import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from './../components/OpenApp';
import { getUseParams, setUrl } from '../utils/baseUtil';
import { setWeixinData } from '../utils/wxShareUtil';
import { dateDiff } from '../utils/dateUtil';

dayjs.locale('zh-cn');

let lastTime = null;
let totalList = [];
const Tag = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [list, setList] = useState([]);
  const [info, setInfo] = useState({});
  const [hasMore, setHasMore] = useState(false);

  const loadingRef = useRef(false);
  const loadingDivRef = useRef(null);
  const intersectionObserver = useRef(null);

  const fetchData = () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    axios.get('/api2/lj_accounts/tag_deep_news', {
      timeout: 5000,
      params: {
        tag: Number(query.id),
        ctime: lastTime,
        os: 'web',
        sv: query.sv,
      },
    }).then((response) => {
      loadingRef.current = false;
      if (response && response.data && response.data.code === 0 && response.data.data) {
        const data = response.data.data;
        if (!lastTime) {
          if (typeof setWeixinData !== 'undefined' && data.share_info) {
            const shareInfo = {
              title: data.share_info.name || '',
              desc: data.share_info.brief || ''
            }

            setWeixinData(data.share_info.name || '', data.share_info.brief || '', data.share_info.img || '');

            handlePostMessage(JSON.stringify(shareInfo || {}))
          }
          setInfo(data.share_info || {});
        }

        let resultList = data.items;
        if (Array.isArray(resultList) && resultList.length > 0) {
          setHasMore(resultList.length >= 20);
          totalList = !lastTime ? resultList : totalList.concat(resultList);
          setList(totalList);
        } else {
          setHasMore(false);
          if (!lastTime) {
            totalList = '没有相关内容';
            setList(totalList);
          }
        }
        lastTime = Array.isArray(totalList) && totalList.length > 0 ? (totalList[totalList.length - 1]?.ctime || '') : '';
      }
    }).catch((error) => {
      loadingRef.current = false;
    });
  };

  useEffect(() => {
    if (!!query.id) {
      fetchData()
    }
  }, [query.id]);

  useEffect(() => {
    if (hasMore) {
      intersectionObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchData();
        }
      }, {
        root: null,
        rootMargin: '0px 0px 100px 0px',
        threshold: 0
      });

      intersectionObserver.current.observe(loadingDivRef.current);
    }
    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.unobserve(loadingDivRef.current);
        intersectionObserver.current.disconnect();
        intersectionObserver.current = null;
      }
    };
  }, [hasMore]);

  const handlePostMessage = (value) => {
    if (window.self !== window.top) {
      window.parent.postMessage(value, '*'); // 发送消息给父页面
    }
  };

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
        <title>{info.name || '标签详情'}</title>
      </Helmet>
      <OpenApp handleOpenApp={window.self !== window.top ? () => handlePostMessage('open') : null} />
      <div className="tag-container">
        {
          (Array.isArray(list) && list.length > 0) ?
            <section className="tag-article">
              {
                list.map(item => {
                  const child = <Fragment>
                    <div className="d-f f-d-c f-b-s f-1">
                      <div className={`${item.cover_img ? '' : 'm-b-25 '}w-100p f-s-16 f-w-n-b c-191c27 l-h-1375 t-o-e l-c-2 o-h tag-article-item-title`}>{item.title}</div>
                      <div className="w-100p d-f f-b-c o-h f-s-12 l-h-116667 c-9ea5bb tag-article-content">
                        <div className="f-g-1 t-a-l o-h t-o-el w-s-n tag-article-source">{item.creator || ''}</div>
                        <div className="d-f f-s-c f-s-0 o-h tag-article-content-right">
                          <div className="f-s-0 o-h t-o-el w-s-n tag-article-time">{dateDiff(!!item.ctime ? item.ctime * 1000 : dayjs().valueOf())}</div>
                          {
                            typeof item.view_num === 'number' && item.view_num ?
                              <div className="o-h t-o-el w-s-n tag-article-viewnum">{item.view_num}阅读</div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {item.cover_img && <img alt="" src={item.cover_img} className="f-s-0 tag-article-item-img" />}
                  </Fragment>;

                  return window.self !== window.top ?
                    <div key={item.id} className="d-f tag-article-item" onClick={() => handlePostMessage(setUrl('deepnews.html', query, item.id))}>
                      {child}
                    </div> :
                    <a key={item.id} className="d-f tag-article-item" href={setUrl('deepnews.html', query, item.id)}>
                      {child}
                    </a>
                })
              }
            </section> : <div className="d-f f-d-c f-c-c l-h-157143 f-s-14 c-9ea5bb lanjing-empty-box">
              <div className="m-b-12 lanjing-empty-box-icon">
                <img className="w-100p h-100p o-f-c" src="https://file.jingpt.com/wap/images/nodata-white.png" alt=""/>
              </div>
              <div className="t-a-c">该标签暂时未关联新闻<br />请稍后再来看看吧</div>
            </div>
        }
        <div ref={loadingDivRef} className="t-a-c">{(Array.isArray(list) && list.length > 0) ? hasMore ? '加载中...' : '到底了' : ''}</div>
      </div>
    </>
  );
};

export default Tag;