const expression = [
  { title: '爱你', src: '&#x1F918' },
  { title: '爱心', src: '&#x2764' },
  { title: '傲慢', src: '&#x1F615' },
  { title: '白眼', src: '&#x1F644' },
  { title: '抱拳', src: '&#x270B' },
  { title: '鄙视', src: '&#x1F612' },
  { title: '闭嘴', src: '&#x1F636' },
  { title: '便便', src: '&#x1F4A9' },
  { title: '不是', src: '&#x261D' },
  { title: '擦汗', src: '&#x1F613' },
  { title: '菜刀', src: '&#x1F52A' },
  { title: '差劲', src: '&#x1F447' },
  { title: '大兵', src: '&#x1F46E' },
  { title: '大哭', src: '&#x1F602' },
  { title: '蛋糕', src: '&#x1F382' },
  { title: '刀', src: '&#x1F5E1' },
  { title: '得意', src: '&#x1F60E' },
  { title: '凋谢', src: '&#x1F940' },
  { title: '调皮', src: '&#x1F61D' },
  { title: '发呆', src: '&#x1F62F' },
  { title: '发怒', src: '&#x1F621' },
  { title: '饭', src: '&#x1F35A' },
  { title: '奋斗', src: '&#x1F623' },
  { title: '尴尬', src: '&#x1F60F' },
  { title: '勾引', src: '&#x1F919' },
  { title: '鼓掌', src: '&#x1F44F' },
  { title: '哈欠', src: '&#x1F62A' },
  { title: '害羞', src: '&#x1F633' },
  { title: '憨笑', src: '&#x1F600' },
  { title: '好的', src: '&#x1F44C' },
  { title: '坏笑', src: '&#x1F60F' },
  { title: '饥饿', src: '&#x1F60B' },
  { title: '惊恐', src: '&#x1F631' },
  { title: '惊讶', src: '&#x1F600' },
  { title: '咖啡', src: '&#x2615' },
  { title: '可爱', src: '&#x263A' },
  { title: '可怜', src: '&#x1F616' },
  { title: '抠鼻', src: '&#x1F643' },
  { title: '骷髅', src: '&#x1F480' },
  { title: '酷', src: '&#x1F60E' },
  { title: '快哭了', src: '&#x1F641' },
  { title: '困', src: '&#x1F634' },
  { title: '篮球', src: '&#x1F3C0' },
  { title: '冷汗', src: '&#x1F613' },
  { title: '礼物', src: '&#x1F381' },
  { title: '流汗', src: '&#x1F625' },
  { title: '流泪', src: '&#x1F62D' },
  { title: '玫瑰', src: '&#x1F339' },
  { title: '难过', src: '&#x2639' },
  { title: '啤酒', src: '&#x1F37A' },
  { title: '瓢虫', src: '&#x1F41E' },
  { title: '撇嘴', src: '&#x1F615' },
  { title: '乒乓', src: '&#x1F3D3' },
  { title: '强', src: '&#x1F44D' },
  { title: '敲打', src: '&#x1F44A' },
  { title: '亲亲', src: '&#x1F619' },
  { title: '糗大了', src: '&#x1F605' },
  { title: '拳头', src: '&#x270A' },
  { title: '弱', src: '&#x1F44E' },
  { title: '色', src: '&#x1F60D' },
  { title: '闪电', src: '&#x26A1' },
  { title: '胜利', src: '&#x270C' },
  { title: '示爱', src: '&#x1F444' },
  { title: '衰', src: '&#x1F628' },
  { title: '睡', src: '&#x1F634' },
  { title: '太阳', src: '&#x1F600' },
  { title: '偷笑', src: '&#x1F61B' },
  { title: '吐', src: '&#x1F922' },
  { title: '微笑', src: '&#x1F642' },
  { title: '委屈', src: '&#x1F626' },
  { title: '握手', src: '&#x270B' },
  { title: '西瓜', src: '&#x1F349' },
  { title: '吓', src: '&#x1F632' },
  { title: '心碎', src: '&#x1F494' },
  { title: '嘘', src: '&#x1F910' },
  { title: '疑问', src: '&#x1F914' },
  { title: '阴险', src: '&#x1F60F' },
  { title: '拥抱', src: '&#x1F917' },
  { title: '右哼哼', src: '&#x1F624' },
  { title: '月亮', src: '&#x1F600' },
  { title: '晕', src: '&#x1F635' },
  { title: '再见', src: '&#x1F44B' },
  { title: '炸弹', src: '&#x1F4A3' },
  { title: '折磨', src: '&#x1F912' },
  { title: '咒骂', src: '&#x1F620' },
  { title: '猪头', src: '&#x1F437' },
  { title: '抓狂', src: '&#x1F621' },
  { title: '龇牙', src: '&#x1F62C' },
  { title: '足球', src: '&#x26BD' },
  { title: '左哼哼', src: '&#x1F624' },
];

export const formatExpression = (data) => {
  if (!data) {
    return '';
  }
  const patternAll = /\[[\u4e00-\u9fa5]+\]/g;
  const patternOne = /\[[\u4e00-\u9fa5]+\]/;
  const contents = data;
  const content = contents.match(patternAll);
  let newContents = contents;
  if (content && content.length > 0) {
    for (let i = 0; i < content.length; i++) {
      let src = '';
      for (let j = 0; j < expression.length; j++) {
        if (`[${expression[j].title}]` === content[i]) {
          src = expression[j].src;
          break;
        }
      }
      newContents = newContents.replace(patternOne, src);
    }
  }
  return newContents;
};

export default {formatExpression};
