export function formatDate(timeString, format) {
  if (!timeString) {
    return '';
  }
  let millisecond = Number(timeString);
  let tiemstamp = new Date(millisecond);
  let weekday = new Array(7);
  weekday[0] = '星期日';
  weekday[1] = '星期一';
  weekday[2] = '星期二';
  weekday[3] = '星期三';
  weekday[4] = '星期四';
  weekday[5] = '星期五';
  weekday[6] = '星期六';
  let fullYear = tiemstamp.getFullYear();
  let month = Number(tiemstamp.getMonth()) + 1 < 10 ? '0' + (String(Number(tiemstamp.getMonth()) + 1)) : Number(tiemstamp.getMonth()) + 1;
  let date = Number(tiemstamp.getDate()) < 10 ? '0' + (String(tiemstamp.getDate())) : tiemstamp.getDate();
  let day = weekday[tiemstamp.getDay()];
  let hour = Number(tiemstamp.getHours()) < 10 ? '0' + (String(tiemstamp.getHours())) : tiemstamp.getHours();
  let minute = Number(tiemstamp.getMinutes()) < 10 ? '0' + (String(tiemstamp.getMinutes())) : tiemstamp.getMinutes();
  let second = Number(tiemstamp.getSeconds()) < 10 ? '0' + (String(tiemstamp.getSeconds())) : tiemstamp.getSeconds();
  if (format === 'YYYY-MM-DD') {
    return fullYear + '-' + month + '-' + date;
  } else if (format === 'YYYY-MM-DD HH:MM:SS') {
    return fullYear + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
  } else if (format === 'YYYY-MM-DD HH:MM') {
    return fullYear + '-' + month + '-' + date + ' ' + hour + ':' + minute;
  } else if (format === 'HH:MM') {
    return hour + ':' + minute;
  } else if (format === 'MM月DD日') {
    return month + '月' + date + '日';
  } else if (format === 'W') {
    return day;
  } else if (format === 'MM-DD HH:MM W') {
    return month + '-' + date + ' ' + hour + ':' + minute + ' ' + day;
  } else if (format === 'MM-DD HH:MM') {
    return month + '-' + date + ' ' + hour + ':' + minute;
  } else if (format === 'HH:MM W') {
    return hour + ':' + minute + ' ' + day;
  } else if (format === 'YYYY-MM') {
    return fullYear + '-' + month;
  }
}

export function isYesterday(time) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const today = year + '/' + month + '/' + day;
  const todayTime = new Date(today).getTime(); // 当天凌晨的时间
  const yesterdayTime = new Date(todayTime - 24 * 60 * 60 * 1000).getTime(); // 昨天凌晨的时间
  return time < todayTime && yesterdayTime <= time;
}

/**
 * 计算时间距离当前时间相差时间（oldTime - nowTime= diffTime）
 * @param time
 * @returns {string}
 */
export function dateDiff(time) {
  if (!time) return '';
  let dateTimeStamp = typeof time === 'number' ? time : new Date(time).getTime()

  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let now = new Date().getTime();
  let nowYear = new Date().getYear();
  let curYear = new Date(dateTimeStamp).getYear();
  let diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  let result = '';
  if (curYear !== nowYear) {
    result = '' + formatDate(dateTimeStamp, 'YYYY-MM-DD HH:MM');
  } else if (dayC >= 2 && curYear === nowYear) {
    result = formatDate(dateTimeStamp, 'MM-DD HH:MM');
  } else if (hourC >= 1) {
    if (isYesterday(dateTimeStamp)) {
      result = '' + '昨天 ' + formatDate(dateTimeStamp, 'HH:MM');
    } else {
      if (dayC > 1) {
        result = formatDate(dateTimeStamp, 'MM-DD HH:MM');
      } else {
        result = '' + parseInt(hourC) + '小时前';
      }
    }
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前';
  } else {
    result = '' + parseInt(diffValue / 1000) + '秒前';
  }
  return result;
}