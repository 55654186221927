import React, {useState} from 'react';
import Helmet from 'react-helmet';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from '../components/OpenApp';
import PersonDetail from '../components/qa/Person';

dayjs.locale('zh-cn');

const Person = (props) => {
  const type = props.type;
  const [title, setTitle] = useState('');

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body person-list" />
        <title>{title}</title>
      </Helmet>
      <OpenApp />
      <PersonDetail type={type} changeTitle={(title) => setTitle(title)} />
    </>
  );
};

export default Person;