import store from '../store';
import Cookies from 'js-cookie';

/**
 * 获取 URl 地址栏的参数，并解析为 json
 * @returns {{}|any}
 */
export function getUseParams(url) {
    let useParams = !!url ? url.split('?') : window.location.href.split('?')
    if (useParams.length <= 1) return {}
    useParams = useParams[1].replace(/&/g, '","').replace(/=/g, '":"')
    return JSON.parse(`{"${useParams}"}`)
}

/**
 * 打开 APP
 */
export function openApp(handleOpenApp) {
    if (typeof handleOpenApp === 'function') {
      handleOpenApp();
    } else {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
      if (isAndroid) {
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.app.lanjing';
      } else if (isIOS) {
          window.location.href = 'https://itunes.apple.com/cn/app/id991711703?mt=8';
      } else {
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.app.lanjing';
      }
      // window.location.href = 'https://a.app.qq.com/o/simple.jsp?plg_auth=1&pkgname=com.app.lanjing';
    }
}

// 相关文章绑定单击事件
export function setUrl(pathname, search, id) {
  let url = (pathname || '') + '?';
  if (typeof search === 'object') {
      const key = Object.keys(search);
      if (!!key.length) {
        let hasId = false;
        key.forEach((item, i) => {
          let symbol = '&';
          if (i === (key.length - 1)) {
            symbol = '';
          }
          if (item === 'id') {
            url += item + '=' + id + symbol;
            hasId = true;
          } else if (item === 'tid') {
            url += item + '=' + id + symbol;
            hasId = true;
          } else {
            url += item + '=' + search[item] + symbol
          }
        });
        if (!hasId && !!id) {
          url += '&id=' + id;
        }
      } else {
        url += 'id=' + (id || '');
      }
  }
  return url
}

export const isWeixin = function() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

export const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);

export function secondsToTime(secs) {
  var hoursDiv = secs / 3600;
  var hours = Math.floor(hoursDiv);
  var minutesDiv = secs % 3600 / 60;
  var minutes = Math.floor(minutesDiv);
  var seconds = Math.round(secs % 3600 % 60);
  if (seconds > 59) {
    seconds = 0;
    minutes = Math.ceil(minutesDiv);
  }
  if (minutes > 59) {
    minutes = 0;
    hours = Math.ceil(hoursDiv);
  }
  return (hours === 0 ? '' : hours > 0 && hours.toString().length < 2 ? '0' + hours + ':' : hours + ':' ) + ( minutes.toString().length < 2 ? '0' + minutes : minutes ) + ':' + ( seconds.toString().length < 2 ? '0' + seconds : seconds );
}

export const lockFixed = {
  lock: function(className) {
    const body = document.querySelector(className);
    if (body) {
      const scrollTop = document.scrollingElement.scrollTop;
      body.classList.add('b-static');
      body.style.top = `-${scrollTop}px`;
    }
  },
  unlock: function(className) {
    const body = document.querySelector(className);
    if (body) {
      body.classList.remove('b-static');
      const top = body.style.top;
      document.scrollingElement.scrollTop = Math.abs(parseInt(top));
      body.style.top = '';
    }
  }
};

export const removeCookies = () => {
  store.setToken('');
  store.setUser('');
  store.setUserInformation({});
  Cookies.remove('_LJWAP_TOKEN', { path: '' });
  Cookies.remove('_LJWAP_USER', { path: '' });
};

export const setReading = (reading_num, unit) => {
  if (!reading_num) {
    return '';
  }
  return reading_num >= 10000 ? ((Math.floor(reading_num / 1000) / 10) + (unit || '')) : reading_num;
};