import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import MeScroll from 'mescroll.js';
import OpenApp from './../components/OpenApp';
import List from '../components/ArticleList';
import MutiClampText from '../components/MutiClampText';
import SwiperStyle from '../components/style/SwiperStyle';
import {getUseParams, openApp, setUrl} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';

let mescrollDom = null;
let page = 1;
let list = [];

const Topic = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [detail, setDetail] = useState({});
  const [hotTopic, setHotTopic] = useState({});
  const [topList, setTopList] = useState([]);
  const [expertList, setExpertList] = useState([]);

  useEffect(() => {
    mescrollDom = initMescroll('body');
    
    axios.get(`/api/v1/topic/detail/${query.id}`, {
      timeout: 5000
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;
          setDetail(data);
          if (typeof setWeixinData !== 'undefined') {
            setWeixinData(data.name, data.description, data.share_logo);
          }
        }
      }
    }).catch((error) => {
    });

    axios.get(`/api/v1/hot_news_topics`, {
      timeout: 5000,
      params: {
        page_num: 1,
        page_size: 30
      }
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;
          setHotTopic(data);
        }
      }
    }).catch((error) => {
    });
    getData(page)

  }, [query.id]);

  const initMescroll = (mescrollId) => {
    return new MeScroll(mescrollId, {
      down: {
        use: false,
      },
      up: {
        offset: 200,
        auto: false,
        warpClass: '',
        noMoreSize: 1,
        htmlLoading: '<div class="d-f f-c-c f-s-14 c-ffffff646566 share-list-status">加载中..</div>',
        htmlNodata: '<div class="d-f f-c-c f-s-14 c-ffffff646566 share-list-status">到底了</div>',
        showNoMore: (mescroll, upwarp) => {
          const listStatus = document.querySelector('.share-list-status');
          if (!!listStatus) {
            listStatus.innerHTML = '到底了';
          }
        },
        callback: () => {
          getData(page)
        },
        isBounce: false,
      },
    });
  };

  const getData = (page_no) => {
    axios.get(`/api/v1/topic_news_list`, {
      timeout: 5000,
      params: {
        page_num: page_no || 1,
        topic_id: query.id,
        page_size: 10,
      }
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && Array.isArray(response.data.data)) {
          if (response.data.data.length > 0) {
            if (response.data.data.length < 10) {
              mescrollDom.endSuccess(response.data.data.length, false);
              mescrollDom.showNoMore();
            } else {
              mescrollDom.endSuccess(response.data.data.length, true);
            }
            list = (page_no === 1 || !page_no) ? response.data.data.splice(3) : list.concat(response.data.data);
            setExpertList(list);
            if (page_no === 1 || !page_no) {
              setTopList(response.data.data.splice(0,3))
            }
          } else {
            if (page_no === 1 || !page_no) {
              list = '没有相关内容';
              setExpertList(list);
            }
            mescrollDom.endSuccess(0, false);
            mescrollDom.showNoMore();
          }
          page = page_no + 1 || 1;
        } else {
          mescrollDom.endErr();
        }
      }
    }).catch((error) => {
      mescrollDom.endErr();
      const expertListLength = expertList.length;
      page = (expertListLength % 10 > 0) ? (expertListLength / 10 + 2) : (expertListLength / 10 + 1);
    });
  };

  return (
    <>
    <Helmet>
      <body className="bg-c-0f1214ffffff s-body" />
      <title>{detail.name || ''}</title>
      <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/mescroll.min.css`} />
    </Helmet>
    <OpenApp />
    <section className="topic-content">
      <section className="w-100p topic-top-box">
        {!!detail.Img && <div className="w-100p h-100p topic-top-background topic-top-background-image" style={{backgroundImage: `url(${detail.Img})`}} />}
        <div className="w-100p h-100p topic-top-background topic-top-background-bg" />
        <div className="d-f f-s-c topic-information">
          <div className="default-topic-information-avatar topic-information-avatar-box">
            {!!detail.Img && <div className="w-100p h-100p topic-information-avatar" style={{backgroundImage: `url(${detail.Img})`}} />}
          </div>
          <div className="f-1 topic-information-content">
            <div className="w-100p f-s-20 c-fff l-c-1 o-h t-o-e topic-information-content-title">{detail.name}</div>
            {
              !!detail.focus_num &&
              <div className="w-100p f-s-13 c-fff l-c-1 o-h t-o-e topic-information-content-follow-num-box">
                <span className="topic-information-content-follow-num">{detail.focus_num}</span>关注
              </div>
            }
          </div>
          <div className="follow-action" onClick={openApp}>关注</div>
        </div>
        <MutiClampText
          className="f-s-13 l-h-17 c-fff"
          text={detail.description}
          line={3}
          openIcon={<span className="d-f f-c-c clamp-icon clamp-open-icon">展开</span>}
          closeIcon={<span className="d-f f-c-c clamp-icon clamp-close-icon">收起</span>}
        />
      </section>

      <section className="bg-c-171b1fffffff topic-dynamic-box">
        <div className="f-s-20 f-w-b c-ffffff191c27 l-h-14 topic-dynamic-title" onClick={() => getData(page)}>最新动态</div>
        <div className="topic-dynamic-list-top">
          <List list={topList} />
        </div>
      </section>
      {
        Array.isArray(hotTopic) && !!hotTopic.length &&
        <SwiperStyle className="bg-c-f8fafd topic-column-box">
          <Swiper
            className="o-h topic-column-banner"
            slidesPerView={'auto'}
            freeMode={true}
          >
            {
              hotTopic.map(item => {
                return (
                  <SwiperSlide
                    className="bg-c-fff d-f f-d-c f-c-c topic-column-banner-item"
                    key={item.tid}
                  >
                    <a href={setUrl(location.pathname, query, item.tid)} className="w-100p d-f f-d-c f-c-c topic-column-banner-item-img-box">
                      <div className="default-topic-column-img topic-column-banner-item-img">
                        {!!item.head_img && <img className="w-100p h-100p o-f-c" src={item.head_img} alt=""/>}
                      </div>
                      <div className="f-s-14 f-w-n-b c-000 t-a-c l-c-1 t-o-e o-h topic-column-banner-item-img-text">{item.name}</div>
                    </a>
                    <div className="follow-action" onClick={openApp}>关注</div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </SwiperStyle>
      }
      <section className="topic-dynamic-list-bottom">
        {Array.isArray(expertList) && !!expertList.length && <List list={expertList} />}
      </section>
    </section>
    
    </>
  );
};

export default Topic;