import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import store from '../store';
import {observer} from 'mobx-react';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from './../components/OpenApp';
import {getUseParams,openApp} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';
import videojs from 'video.js';
import 'videojs-contrib-hls.js';

dayjs.locale('zh-cn');
var player;// 视频控制器

const VideoDetail = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [detail, setDetail] = useState({});
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [playError, setPlayError] = useState(false);
  const token = store.token || '';

  useEffect(() => {
    if (!!query.id) {
      getDetail()
    }
  }, [query.id]);

  const getDetail = () => {
    axios.get(`/api2/user/video/${query.id}`, {
      timeout: 5000,
      params: {
        os: query.os || 'web',
        version: query.sv,
      },
      headers: {token},
    }).then((res) => {
      if (res.data && res.data.code === 0 && res.data.data) {
        if(!!player){
          player.dispose();
        }
        setDetail(res.data.data);
        initPlayer();
        setShowPlayButton(true)
        const {ctime,title} = res.data.data;
        if (typeof setWeixinData !== 'undefined') {
          var desc = dayjs(ctime * 1000).format("YYYY-MM-DD HH:mm");
          setWeixinData(title, desc, '');
        }
      }
    }).catch((error) => {});
  };

  //初始化播放器
  const initPlayer  = (is_live) => {
    player = videojs('myVideo', {
      controlBar: {
        pictureInPictureToggle: false,// 禁用画中画
        currentTimeDisplay:true,
        timeDivider:true,
        durationDisplay:true,
      },
    }, function onPlayerReady() {
      if (is_live) {
        // 开始加载
        this.on('loadstart', function () {
          // 去除加载动画
          if (!!document.querySelector('.vjs-loading-spinner')) {
            document.querySelector('.vjs-loading-spinner').style.display = 'none'
          }
        });
      }
      // 视频播放
      this.on('play', function () {
        setShowPlayButton(false);// 隐藏播放按钮
      });
      // 视频结束
      this.on('ended', function () {
        setShowPlayButton(true);// 绘制播放按钮
      });
      // 视频暂停
      this.on('pause', function () {
        setShowPlayButton(true);// 绘制播放按钮
      });
      // 视频错误
      this.on('error', function () {
        setShowPlayButton(false);// 隐藏播放按钮
        // 去除错误交互
        if (!!document.querySelector('.vjs-error-display')) {
          document.querySelector('.vjs-error-display').style.display = 'none'
        }
        setPlayError(true)
      });
    });
    if (!!document.querySelector('.vjs-big-play-button')) {
      document.querySelector('.vjs-big-play-button').style.display = 'none'
    }
  };

  // 播放回放视频事件
  const play = () => {
    if (player) {
      player.play();
    }
  };


  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body"/>
        <title>{detail.title || '直播'}</title>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/video-js.css`} />
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/mescroll.min.css`} />
      </Helmet>
      <div className="w-100p m-w-480 live-top-fix">
        <OpenApp />
        <section className="bg-c-0f1214ffffff live-wrap">
          {
            playError &&
            <div className="d-f f-c-c f-s-12 l-h-1 c-fff bg-c-000 live-status live-status-error" onClick={openApp}>打开APP播放</div>
          }
          {
            showPlayButton &&
            <div className="d-f f-c-c m-w-480 bg-c-0f1214-70 live-box-mask">
              <div onClick={play} className="play-b"><i className="iconfont icon-bofang" /></div>
            </div>
          }

          <div className="w-100p h-100p live-box m-w-480">
            {
              detail.video_url ?
                <div className="w-100p h-100p" id="video-container">
                  <video id="myVideo" className="w-100p h-100p video-js vjs-default-skin" controls poster={detail&&detail.detail_img} preload="none" data-setup="{}" webkit-playsinline="true" playsInline x5-playsinline="true" x-webkit-airplay='allow' >
                    {
                      detail.video_url && detail.video_url.indexOf('.m3u8') > -1 ?
                        <source src={detail.video_url} type="application/x-mpegURL" /> :
                        <>
                          <source src={detail.video_url} type="video/mp4" />
                          <source src={detail.video_url} type="video/webm" />
                          <source src={detail.video_url} type="video/ogg" />
                        </>
                    }
                  </video>
                </div>
              : <img className="w-100p h-100p o-f-c" alt="" src={detail&&detail.detail_img} />
            }
          </div>
        </section>

        <section className="bg-c-171b1fffffff live-title-wrap">
          <div className="live-title-box">
            <div className="p-r o-h f-s-15 f-w-b l-h-141667 c-ffffff191c27 live-title-top">
              {detail.title}
            </div>      
            <div className="d-f f-c-c o-h f-s-12 l-h-15 live-title-bottom">
              <div className="w-100p live-title-bottom-time c-6465669395a2">
                {dayjs(detail.ctime * 1000).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default observer(VideoDetail);