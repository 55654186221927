import React, { useState, useEffect, useRef } from 'react';
import 'intersection-observer';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperStyle from '../../components/style/SwiperStyle';
import {getUseParams, setUrl} from '../../utils/baseUtil';
import {dateDiff} from '../../utils/dateUtil'

const InfiniteScroll = (props) => {
  const location = useLocation();
  const query = getUseParams(location.search);

  const [data, setData] = useState(props.data);
  const [isFixed, setIsFixed] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(0);
  const [list, setList] = useState([]);
  const [moreResult, setMoreResult] = useState('');

  const navRef = useRef(null);
  const swiperRef = useRef(null);
  const observer = useRef(null);
  const observerTarget = useRef(null);

  useEffect(() => {
    if (!!props.data[0] && Array.isArray(props.data[0].target_ids)) {
      setList(props.data[0].target_ids);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !moreResult) {
        let article = data[navActiveIndex].target_ids;
        getMoreItem(article[article.length - 1]).then((newArticle) => {
          if (newArticle.length > 0) {
            const newList = [...list, ...newArticle];
            setList(newList);
            article = newList;
            data[navActiveIndex].target_ids = article;
            setData(data);
            if (newArticle.length < 12) {
              setMoreResult('没有更多了');
            }
          } else {
            setMoreResult('没有更多了');
          }
        }).catch(error => {
          setMoreResult(error);
        });
      }
    }, {
      root: null,
      rootMargin: '0px 0px 50px 0px',
      threshold: 0
    });

    if (observerTarget.current) {
      observer.current.observe(observerTarget.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [moreResult, list]);

  useEffect(() => {
    if (!!props.data[navActiveIndex] && Array.isArray(props.data[navActiveIndex].target_ids)) {
      setList(props.data[navActiveIndex].target_ids);
    } else {
      setList([]);
    }
    setMoreResult('');
  }, [navActiveIndex]);

  const handleScroll = () => {
    if (navRef.current) {
      const navRect = navRef.current.getBoundingClientRect();
      setIsFixed(navRect.top - 50 <= 0);
    }
  };

  const getMoreItem = async (data) => {
    return new Promise((resolve, reject) => {
      axios.get(`/api2/collection/more/${data.sid}`, {
        timeout: 5000,
        params: {
          sid: data.sid,
          lastTime: data.create_time
        }
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data) {
            resolve(response.data.data)
          } else {
            reject('加载更多失败')
          }
        } else {
          reject('加载更多失败')
        }
      }).catch(() => {
        reject('加载更多失败')
      });
    });
  };

  return (
    <>
    <section ref={navRef} className="p-r collection-nav-wrap">
      <SwiperStyle className={`${isFixed ? 'p-f m-w-480' : 'p-a m-c'} w-100p bg-c-fff collection-nav-box ${isFixed ? 'collection-nav-fixed-box' : ''}`}>
        <Swiper
          ref={swiperRef}
          className="o-h b-c-f5f5f5 collection-swiper-container"
          slidesPerView="auto"
          spaceBetween={20}
          resistanceRatio={0}
          initialSlide={0}
        >
          {
            props.data.map((item, index) => {
              return (
                <SwiperSlide
                  className={`p-r d-f f-s-c f-s-16 l-h-1 collection-nav ${navActiveIndex === index ? 'collection-nav-active' : ''}`}
                  key={item.sid}
                  onClick={() => {
                    if (!!swiperRef.current) {
                      swiperRef.current.swiper.slideTo(index, 0, false);
                      setNavActiveIndex(swiperRef.current.swiper.clickedIndex)
                    }
                  }}
                >
                  {item.title}
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </SwiperStyle>
    </section>
    <section>
    {
      !!list.length ?
      <>
      {
        list.map((item, index) => (
          <a key={item.aid} className="d-f b-m-w-14 m-t-20 m-b-20" href={setUrl('deepnews.html', query, item.aid)}>
            <div className="f-g-1 d-f f-d-c o-h">
              <div className="f-g-1 collection-list-title">
                <div className="o-h f-s-16 f-w-b l-h-1375 c-191c27 t-o-e l-c-2">{item.title}</div>
              </div>
              <div className="d-f f-b-c l-h-1">
                <div className="f-g-1 d-f f-s-c o-h">
                  <div className="o-h f-s-13 c-191c27 w-s-n t-o-el">{item.creator}</div>
                </div>
                <div className="f-s-0 f-s-12 c-9ea5bb">{dateDiff(item.create_time * 1000)}</div>
              </div>
            </div>
            {
              !!item.cover_img &&
              <div className="f-s-0 m-l-10 o-h collection-list-image">
                <img className="w-100p h-100p o-f-c" src={item.cover_img} alt=""/>
              </div>
            }
          </a>
        ))
      }
      {
        !moreResult ? <div ref={observerTarget}/> : <div className="f-s-14 l-h-2 c-9ea5bb t-a-c">{moreResult}</div>
      }
      </> :
      <div>
        <div className="collection-list-empty" />
        <div className="f-s-14 l-h-157143 c-9ea5bb t-a-c">暂无内容</div>
      </div>
    }
    </section>
   </>
  );
};

export default InfiniteScroll;