import React, {useState, useEffect, useRef} from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import md5 from 'md5';
import MeScroll from 'mescroll.js';

let mescrollDom = null;
let metoken = '';
let page = 1;
let list = [];
let timer = null;

const Expert = () => {
  const [token, setToken] = useState('');
  const [codeValue, setCodeValue] = useState('');

  const [expertList, setExpertList] = useState([]);
  const [showLogin, setShowLogin] = useState(true);
  const [nameInputError, setNameInputError] = useState(''); //用户名错误
  const [passwordInputError, setPasswordInputError] = useState(''); //password错误
  const [loginError, setLoginError] = useState('');
  const [showCode, setShowCode] = useState(false);  // login弹窗错误
  const [codeInputError, setCodeInputError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [reLogin, setReLogin] = useState(false);

  const [errorMessage, setErrorMessage] = useState(''); // 弹窗错误

  const searchInput = useRef('');
  const nameInput = useRef('');
  const passwordInput = useRef('');
  const codeInput = useRef('');

  useEffect(() => {
    mescrollDom = initMescroll('body');
  }, []);

  const initMescroll = (mescrollId) => {
    return new MeScroll(mescrollId, {
      down: {
        use: false,
      },
      up: {
        offset: 200,
        auto: false,
        warpClass: '',
        noMoreSize: 1,
        htmlLoading: '<div class="d-f f-c-c f-s-14 c-afb0b3646566 bg-c-171b1fffffff expert-list-status">加载中..</div>',
        htmlNodata: '<div class="d-f f-c-c f-s-14 c-afb0b3646566 bg-c-171b1fffffff expert-list-status">到底了</div>',
        isLock: true,
        showNoMore: (mescroll, upwarp) => {
          // $('.expert-list-status').text('到底了');
          const listStatus = document.querySelector('.expert-list-status');
          if (!!listStatus) {
            listStatus.innerHTML = '到底了';
          }
        },
        callback: () => {
          getData(page)
        },
        isBounce: false,
        toTop: {
          warpId : null,
          src : null,
          html: '<div class="d-f f-c-c w-100p h-100p bg-c-0f1214ffffff expert-mescroll-top"><svg width="46" height="46" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg"><path class="f-0f1214ffffff" d="M14,14 L46,14 L46,18 L14,18 L14,14 Z M30.0010855,18 L46,34.5530395 L36.6873069,34.5530395 L36.7268208,47 L23.3170287,47 L23.3170287,34.5530395 L14,34.5530395 L30.0010371,18 L30.0010855,18 Z"></path></svg></div>',
          offset : 200,
          warpClass : 'mescroll-totop',
          showClass : 'mescroll-fade-in',
          hideClass : 'mescroll-fade-out',
          duration : 200,
          supportTap : false,
          btnClick : null
        }
      },
    });
  };

  // 用户名密码登陆
  const login = () => {
    axios.get('/cms/login', {
      timeout: 5000,
      params: {
        name: nameInput.current.value,
        password: md5(passwordInput.current.value)
      },
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0 && !!response.data.data) {
          if (!!response.data.data.token) {
            metoken = response.data.data.token;
            setToken(metoken);
            closeVerification();
            setShowCode(true);
          }
        } else {
          setLoginError(response.data.data.msg || '抱歉，连接错误')
        }
      } else {
        setLoginError('抱歉，连接错误')
      }
    }).catch((error) => {
      metoken = '';
      setToken(metoken);
      if (!!error.response && !!error.response.data) {
        setLoginError(error.response.data.msg || '抱歉，连接错误')
      } else {
        setLoginError('抱歉，连接错误')
      }
    });
  };

  // 设置错误弹层
  const setErrorPopup = (text) => {
    setErrorMessage(text);
    clearTimeout(timer);
    timer = setTimeout(() => {
      closeErrorPopup();
    }, 5000);
  };

  // 关闭错误弹层
  const closeErrorPopup = () => {
    clearTimeout(timer);
    setErrorMessage('');
  };

  // 获取数据
  const getData = (page_no) => {
    axios.get('/cms/proficient', {
      timeout: 5000,
      headers:{ token: metoken },
      params: {
        page: page_no || 1,
        limit: 10,
        keyword: searchInput.current.value || ''
      },
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0 && Array.isArray(response.data.data)) {
          if (response.data.data.length > 0) {
            if (response.data.data.length < 10) {
              mescrollDom.endSuccess(response.data.data.length, false);
              mescrollDom.showNoMore();
            } else {
              mescrollDom.endSuccess(response.data.data.length, true);
            }
            closeCode();
            list = !page_no ? response.data.data : list.concat(response.data.data);
            setExpertList(list);
          } else {
            if (!page_no) {
              list = '没有搜索到相关内容';
              setExpertList(list);
            }
            mescrollDom.endSuccess(0, false);
            mescrollDom.showNoMore();
          }
          page = response.data.page_no + 1 || 1;
        } else {
          mescrollDom.endErr();
          setErrorPopup(response.data.msg || '抱歉，连接错误');
        }
      } else {
        setErrorPopup('抱歉，连接错误')
      }
    }).catch((error) => {
      mescrollDom.endErr();
      mescrollDom.lockUpScroll(true);
      const expertListLength = expertList.length;
      page = (expertListLength % 10 > 0) ? (expertListLength / 10 + 2) : (expertListLength / 10 + 1);
      if (!!error.response && !!error.response.data) {
        if (error.response.data.msg === '未授权') {
          setCodeInputError('');
          setCodeError((error.response.data.msg || '') + '，点击重新登陆');
          // 重新登陆
          metoken = '';
          setToken(metoken);
          setCodeValue('');
          setReLogin(true);
        } else {
          setErrorPopup(error.response.data.msg || '抱歉，连接错误');
        }
      } else {
        setErrorPopup('抱歉，连接错误');
      }
    });
  };

  const handleReLogin = () => {
    setCodeInputError('');
    setCodeError('');
    closeCode();
    setShowLogin(true);
    setReLogin(false);
    searchInput.current.blur();
    mescrollDom.lockUpScroll(true);
  };

  // 关闭登陆弹层
  const closeVerification = () => {
    nameInput.current.value = '';
    passwordInput.current.value = '';
    setShowLogin(false);
  };

  // 关闭验证弹层
  const closeCode = () => {
    setReLogin(false);
    setShowCode(false);
  };

  // 点击搜索
  const handleSearchClear = (event) => {
    if (!token) {
      setShowLogin(true);
      searchInput.current.blur();
      mescrollDom.lockUpScroll(true);
    } else {
      if (!codeValue) {
        setShowCode(true);
        searchInput.current.blur();
        mescrollDom.lockUpScroll(true);
      } else {
        if (event === 'search') {
          getData();
        }
      }
    }
  };

  // 点击登陆确定
  const handleLoginConfirm = () => {
    if (!nameInput.current.value) {
      setNameInputError('请输入用户名');
    } else {
      setNameInputError('')
    }
    if (!passwordInput.current.value) {
      setPasswordInputError('请输入密码');
    } else {
      setPasswordInputError('')
    }
    if (!!nameInput.current.value && !!passwordInput.current.value) {
      login();
    }
  };

  // 点击登陆取消
  const handleLoginCancel = () => {
    setNameInputError('');
    setPasswordInputError('');
    setLoginError('');
    closeVerification();
  };

  // 点击验证确定
  const handleCodeConfirm = () => {
    if (!codeInput.current.value) {
      setCodeInputError('请输入密码');
    } else {
      setCodeInputError('')
    }
    if (!!codeInput.current.value && codeInput.current.value === 'cls888') {
      setCodeValue(codeInput.current.value);
      mescrollDom.lockUpScroll(false);
      getData(page);
    } else {
      setCodeInputError('密码错误');
    }
  };

  // 点击验证取消
  const handleCodeCancel = () => {
    setCodeInputError('');
    setCodeError('');
    closeCode();
  };

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body expert-body" />
        <title>专家采访资源</title>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/mescroll.min.css`} />
      </Helmet>
      <section className="d-f f-b-c b-p-w-15 bg-c-171b1fa8e3ff-e4f6ff expert-search-box">
        <div className="d-f f-s-c f-s-0 o-h bg-c-0f1214ffffff expert-search-input-box">
          <div className="f-s-0 d-f f-c-c expert-search-input-icon">
            <svg width="18" height="18" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path fill="#E64545" opacity="0.195219494" d="M2.63603897,15.363961 C1.00735931,13.7352814 0,11.4852814 0,9 C0,4.02943725 4.02943725,-3.55271368e-15 9,-3.55271368e-15 C13.9705627,-3.55271368e-15 18,4.02943725 18,9 C18,11.4852814 16.9926407,13.7352814 15.363961,15.363961 L9,9 L2.63603897,15.363961 Z" />
              <circle stroke="#E64545" className="f-tffffff" strokeWidth="0.5" opacity="0.350795201" cx="9" cy="9" r="8.75" />
              <circle stroke="#E64545" className="f-tffffff" strokeLinecap="round" cx="9" cy="9" r="6" />
              <circle stroke="#E64545" className="f-tffffff" strokeWidth="0.5" opacity="0.350795201" cx="9" cy="9" r="2.75" />
              <path d="M9,9 L16.5,16.5" stroke="#E64545" strokeLinecap="round" />
            </svg>
          </div>
          <input type="search" ref={searchInput} autoComplete="off" className="f-g-1 w-100p h-100p f-s-13 c-afb0b3191c27 bg-c-0f1214ffffff expert-search-input" onFocus={() => handleSearchClear('focus')} onSearch={() => handleSearchClear('search')} />
        </div>
        <div className="f-g-1 f-s-13 c-afb0b3191c27 expert-search-clear" onClick={() => handleSearchClear('search')}>搜索</div>
      </section>

      <section>
        {Array.isArray(expertList) && !!expertList.length && <div className="f-s-20 f-w-b l-h-15 c-ffffff191c27 expert-list-tips">最新入驻</div>}
        <div className="expert-list-wrap">
          {
            typeof expertList === 'string' && !!expertList ? <div className="d-f f-c-c f-s-14 c-afb0b3646566 bg-c-171b1ff8fafd expert-list-empty">{expertList}</div> :
            Array.isArray(expertList) && !!expertList.length && expertList.map((value) => {
              return (
                <div key={value.id} className="expert-list-box">
                  <div className="bg-c-171b1ff8fafd expert-list">
                    <div className="d-f f-s-c expert-name-box">
                      {!!value.head_img && <img alt="" className="f-s-0 expert-avatar" src={value.head_img}/>}
                      <div className="w-100p">
                        <div className="f-s-16 l-h-15 c-ffffff333333 expert-name">{value.name + ' ' + (value.phone || '')}</div>
                        <div className="d-f f-s-c f-s-13 l-h-123077 w-b-a">
                          <div className="c-afb0b3646566 expert-company">{(Array.isArray(value.company) && value.company[0] && value.company[0].name ? value.company[0].name : '') + ' ' + value.position}</div>
                          {
                            !!value.city &&
                            <div className="f-s-0 d-f f-s-c expert-city">
                              <div className="d-f f-c-c expert-company-icon">
                                <svg width="14" height="19" viewBox="0 0 14 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                  <path className="f-2c3840f4d49c" d="M8.62053509,17.8265987 C8.49586186,17.9989689 8.34448635,18.1503445 8.17211613,18.2750177 C7.27712584,18.9223532 6.0268238,18.7215893 5.37948836,17.826599 L5.37947309,17.82661 C1.7931577,12.8682573 0,9.25938729 0,7 C0,3.13400675 3.13400675,0 7,0 C10.8659932,0 14,3.13400675 14,7 C14,9.25938558 12.206845,12.8682518 8.62053509,17.8265987 Z M7,10 C8.65685425,10 10,8.65685425 10,7 C10,5.34314575 8.65685425,4 7,4 C5.34314575,4 4,5.34314575 4,7 C4,8.65685425 5.34314575,10 7,10 Z"/>
                                </svg>
                              </div>
                              <div className="c-646566">{value.city}</div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    {!!value.sign && <div className="f-s-14 l-h-157143 c-afb0b3abafb8 expert-content">{value.sign}</div>}
                    {
                      !!value.tags && Array.isArray(value.tags) && !!value.tags.length &&
                      <div className={`d-f f-s-s expert-tag-box${!!value.sign ? ' expert-tag-content-box' : ''}`}>
                        <div className="f-s-0 f-s-12 c-646566 expert-tag-title">擅长</div>
                        <div className="d-f f-s-c f-w f-s-12 l-h-15">
                          {
                            value.tags.map((tagValue) => {
                              return <div key={tagValue.id} className="c-afb0b39ea5bb b-c-2c3840t bg-c-tf1f6ff expert-tag">{tagValue.name}</div>
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>

      {
        showLogin &&
        <section className="m-w-480 login-popup-box login-popup-verification-box">
          <div className="w-100p h-100p bg-c-000-30 login-popup-mask" onClick={handleLoginCancel} />
          <div className="o-h bg-c-171b1fffffff login-popup">
            <div className="f-s-20 f-w-b l-h-14 c-ffffff191c27 t-a-c login-popup-title">验证</div>
            <div className={`f-s-14 l-h-142858 o-h login-popup-input-box ${!!nameInputError ? 'login-popup-input-error' : ''}`}>
              <input type="text" placeholder="用户名" autoComplete="off" ref={nameInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
              {!!nameInputError && <div className="f-s-12 c-d95757 login-popup-input-message">{nameInputError}</div>}
            </div>
            <div className={`f-s-14 l-h-142858 o-h login-popup-input-box ${!!passwordInputError ? 'login-popup-input-error' : ''}`}>
              <input type="password" placeholder="密码" autoComplete="new-password" ref={passwordInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
              {!!passwordInputError && <div className="f-s-12 c-d95757 login-popup-input-message">{passwordInputError}</div>}
            </div>
            {
              loginError && <div className="f-s-12 c-d95757 login-popup-error">{loginError}</div>
            }
            <div className="d-f f-b-c f-s-14 l-h-142858 login-popup-button-box">
              <div className="d-f f-c-c w-100p h-100p c-6465669ea5bb login-popup-button" onClick={handleLoginCancel}>
                取消
              </div>
              <div className="d-f f-c-c w-100p h-100p c-afb0b3191c27 login-popup-button" onClick={handleLoginConfirm}>
                确定
              </div>
            </div>
          </div>
        </section>
      }

      {
        showCode &&
        <section className="m-w-480 login-popup-box expert-popup-code-box">
          <div className="w-100p h-100p bg-c-000-30 login-popup-mask" onClick={handleCodeCancel} />
          <div className="o-h bg-c-171b1fffffff login-popup">
            <div className="f-s-20 f-w-b l-h-14 c-ffffff191c27 t-a-c login-popup-title">{reLogin ? '重新登陆' : '输入密码'}</div>
            {
              !reLogin &&
              <div className={`f-s-14 l-h-142858 o-h login-popup-input-box ${!!codeInputError ? 'login-popup-input-error' : ''}`}>
                <input type="password" placeholder="输入密码" autoComplete="new-password" ref={codeInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
                {!!codeInputError && <div className="f-s-12 c-d95757 login-popup-input-message">{codeInputError}</div>}
              </div>
            }
            {
              codeError && <div className="f-s-12 c-d95757 login-popup-error" onClick={handleReLogin}>{codeError}</div>
            }
            <div className="d-f f-b-c f-s-14 l-h-142858 login-popup-button-box">
              <div className="d-f f-c-c w-100p h-100p c-6465669ea5bb login-popup-button" onClick={handleCodeCancel}>
                取消
              </div>
              <div className="d-f f-c-c w-100p h-100p c-afb0b3191c27 login-popup-button" onClick={handleCodeConfirm}>
                确定
              </div>
            </div>
          </div>
        </section>
      }

      {
        !!errorMessage &&
        <section className="m-w-480 login-popup-box expert-popup-error-box">
          <div className="w-100p h-100p bg-c-000-30 login-popup-mask" onClick={closeErrorPopup} />
          <div className="o-h bg-c-171b1fffffff login-popup" onClick={closeErrorPopup}>
            <div className="f-s-16 l-h-14 c-ffffff191c27 t-a-c login-popup-title">{errorMessage}</div>
          </div>
        </section>
      }
    </>
  );
};

export default Expert;