import React, {useState, useEffect, useRef} from 'react';
import {openApp, isWeixin, isMobile} from '../utils/baseUtil';

const OpenAppChannel = (props) => {
  const [isHide, setIsHide] = useState(false);

  const wxRef = useRef(null);

  useEffect(() => {
    if (wxRef.current) {
      wxRef.current.addEventListener('ready', function(e){
        console.log('标签初始化完毕，可以进行点击操作')
      });
      wxRef.current.addEventListener('launch', function(e){
        console.log('用户点击跳转按钮并对确认弹窗进行操作后触发')
      });
      wxRef.current.addEventListener('error', function(e){
        console.log('错误', e.detail);
        setIsHide(true);
      });
      document.addEventListener('WeixinOpenTagsError', function (e) {
        console.error(e.detail.errMsg) // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
        setIsHide(true);
      });
    }
  }, []);

  return (
    isWeixin() && isMobile && !!props.openUrl && !isHide ?
    <div className="p-r">
      {props.children}
      <wx-open-launch-app
        appid="wxd9ef1ece2b0d029a"
        class="d-b p-a m-c w-100p h-100p"
        extinfo={props.openUrl}
        ref={wxRef}
      >
        <script type="text/wxtag-template">
          <style>
            {'.open-button{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0}'}
          </style>
          <div class="open-button"/>
        </script>
      </wx-open-launch-app>
    </div> :
    <div onClick={() => openApp(props.handleOpenApp)}>
      {props.children}
    </div>
  )
};

export default React.memo(OpenAppChannel);