import React from 'react';
import {openApp} from '../../utils/baseUtil';

const Avatar = (props) => {
  const item = props.item || {};
  const size = props.size || 'large';
  const type = !!props.type ? props.type : !!item.type ? (item.type === 2 ? 'reporter' : item.type === 3 ? 'proficient' : '') : '';
  const nofollow = !!props.nofollow;
  const isArticle = props.isArticle;

  return (
    <div className={`d-f f-s-c ${size !== 'small' ? 'person-top-basic-box' : ''}`}>
      <div className={`f-s-0 o-h default-avatar person-top-avatar-box ${size === 'small' ? 'person-top-avatar-small-box' : ''}`}>
        {!!item.head_img && <img alt="" className="w-100p h-100p o-f-c" src={item.head_img} />}
      </div>
      {
        (!!item.tid || !!item.from_user_id || !!item.uid) &&
        <>
        <div className="d-f f-d-c f-b-s f-1 person-top-name-box">
          <div className={`w-100p d-f f-b-c ${size === 'small' ? 'person-top-name-small' : 'person-top-name'}`}>
            <div className="d-f f-s-c person-top-name-text">
              <div className={`t-o-e l-c-1 o-h ${size === 'small' ? 'f-s-14' : 'f-s-24 l-h-125'} f-w-n-b c-ffffff191c27 person-top-name-detail`}>{item.real_name || ''}</div>
              {
                !!type &&
                <div className="d-f f-c-c person-top-name-icon-box">
                  {
                    type === 'reporter' ?
                    <img src="https://file.jingpt.com/wap/images/reporter-237.png" alt=""/> :
                    <img src="https://file.jingpt.com/wap/images/expert-237.png" alt=""/>
                  }
                </div>
              }
            </div>
          </div>
          <div className={`l-c-1 t-o-e o-h f-s-13 person-top-company ${size === 'small' ? `${isArticle ? 'c-ffffff9395a2' : 'c-afb0b39395a2'}` : `${isArticle ? 'c-ffffff9395a2' : 'c-7d7e809395a2'}`}`}>
            {item.company_name || ''} {item.title || item.position || ''}
            {
              !!item.city &&
              <>
                <svg className="person-top-city-svg" width="8" height="11" viewBox="0 0 10 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <path className={isArticle ? 'f-f4d49c' : 'f-9ea5bb'} d="M6.08035673,12.2177325 C5.99724124,12.332646 5.89632424,12.433563 5.78141075,12.5166785 C5.18475056,12.9482354 4.35121587,12.8143928 3.91965891,12.2177326 L3.91964873,12.21774 C1.5287718,8.91217153 0.333333333,6.5062582 0.333333333,5 C0.333333333,2.42267117 2.42267117,0.333333333 5,0.333333333 C7.57732883,0.333333333 9.66666667,2.42267117 9.66666667,5 C9.66666667,6.50625705 8.47123002,8.91216787 6.08035673,12.2177325 Z M5,7 C6.1045695,7 7,6.1045695 7,5 C7,3.8954305 6.1045695,3 5,3 C3.8954305,3 3,3.8954305 3,5 C3,6.1045695 3.8954305,7 5,7 Z" />
                </svg>
                {item.city}
              </>
            }
          </div>
        </div>
        {!nofollow && <div className={`d-f f-c-c f-s-14 c-1a8cff person-top-button ${size === 'small' ? 'person-top-button-small' : ''} ${isArticle ? 'bg-c-d95757339aff-84bfff person-top-button-article' : ''}`} onClick={openApp}>关注</div>}
        </>
      }
    </div>
  )
};

export default Avatar;