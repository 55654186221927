import React, {useState, useRef, useEffect} from 'react';
import store from '../store';
import {observer} from 'mobx-react';
import axios from 'axios';
import Cookies from 'js-cookie';

let codeTimer = null;

const Login = (props) => {
  const {type, isShow, setIsShow} = props;
  const cookieToken = Cookies.get('_LJWAP_TOKEN');
  const cookieUser = Cookies.get('_LJWAP_USER');

  const [showLogin, setShowLogin] = useState(false);
  const [nameInputError, setNameInputError] = useState(''); //用户名错误
  const [passwordInputError, setPasswordInputError] = useState(''); //password错误
  const [codeInputError, setCodeInputError] = useState(''); //code错误
  const [loginError, setLoginError] = useState('');
  const [countDown, setCountDown] = useState(0);
  const [policy, setPolicy] = useState(false);

  const nameInput = useRef('');
  const passwordInput = useRef('');
  const codeInput = useRef('');
  useEffect(() => {
    if (!!cookieToken && !!cookieUser) {
      store.setToken(cookieToken);
      store.setUser(cookieUser);
    } else {
      setShowLogin(true);
    }
  }, [cookieToken, cookieUser]);

  useEffect(
    () => {
      setShowLogin(isShow);
    },
    [isShow]
  );

  useEffect(() => {
    if (countDown > 0) {
      codeTimer = setInterval(() => {
        setCountDown(n => {
          if (n === 1) {
            clearInterval(codeTimer)
          }
          return n - 1;
        });
      }, 1000)
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(codeTimer)
    };
  }, [countDown]);

  useEffect(() => {
    document.body.addEventListener('focusout', () => {
      //ios软键盘收起的事件处理
      window.scrollBy(0, 5);
    });
  },[])


  // 点击登陆确定
  const handleLoginConfirm = () => {
    let nameError = false;
    if (!nameInput.current.value) {
      setNameInputError('请输入手机号');
      nameError = true;
    } else {
      if (!(/^1\d{10}$/.test(nameInput.current.value))) {
        setNameInputError('请输入正确的手机号');
        nameError = true;
      } else {
        setNameInputError('');
      }
    }
    let passwordError = false;
    if (type === 'password') {
      if (!passwordInput.current.value) {
        setPasswordInputError('请输入密码');
        passwordError = true;
      } else {
        setPasswordInputError('')
      }
    }
    let codeError = false;
    if (type === 'code') {
      if (!codeInput.current.value) {
        setCodeInputError('请输入验证码');
        codeError = true;
      } else {
        setCodeInputError('')
      }
    }
    if (!policy) {
      setLoginError('请同意用户隐私协议')
    }
    if (!nameError && ((!passwordError && type === 'password') || (!codeError && type === 'code')) && policy) {
      userLoginOrRegister();
    }
  };

  const userLoginOrRegister = () => {
    axios({
      method: 'post',
      url: '/api2/user/login',
      data: {
        'phone': nameInput.current.value,
        'verify_code': codeInput.current.value,
        'os': 'web'
      },
      timeout: 5000,
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0 && !!response.data.data && !!response.data.data.token) {
          store.setToken(response.data.data.token);
          store.setUser(nameInput.current.value);
          Cookies.set('_LJWAP_TOKEN', response.data.data.token, { expires: 7, path: '' });
          Cookies.set('_LJWAP_USER', nameInput.current.value, { expires: 7, path: '' });
          close('ok');
        } else {
          Cookies.remove('_LJWAP_TOKEN', { path: '' });
          Cookies.remove('_LJWAP_USER', { path: '' });
          setLoginError(response.data.msg || '抱歉，连接错误')
        }
      } else {
        store.setToken('');
        store.setUser('');
        Cookies.remove('_LJWAP_TOKEN', { path: '' });
        Cookies.remove('_LJWAP_USER', { path: '' });
        setLoginError('抱歉，连接错误')
      }
    }).catch((error) => {
      store.setToken('');
      store.setUser('');
      Cookies.remove('_LJWAP_TOKEN', { path: '' });
      Cookies.remove('_LJWAP_USER', { path: '' });
      if (!!error.response && !!error.response.data) {
        setLoginError(error.response.data.msg || '抱歉，连接错误')
      } else {
        setLoginError('抱歉，连接错误')
      }
    });
  };

  const handleCodeConfirm = () => {
    if(document.body){
      document.body.scrollTop = 0;
　　}
　　if(document.documentElement){
　　　document.documentElement.scrollTop = 0;
　　}
    let nameError = false;
    if (!nameInput.current.value) {
      setNameInputError('请输入手机号');
      nameError = true;
    } else {
      if (!(/^1\d{10}$/.test(nameInput.current.value))) {
        setNameInputError('请输入正确的手机号');
        nameError = true;
      } else {
        setNameInputError('');
      }
    }
    if (!nameError) {
      getValidateCode();
    }
  };

  // 获取验证码
  const getValidateCode = () => {
    axios({
      method: 'post',
      url: '/api2/sms/send',
      data: {
        phone: nameInput.current.value,
        template: 1,
        source: 'web'
      },
      timeout: 5000,
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0) {
          clearInterval(codeTimer);
          setCountDown(60);
        } else {
          setCountDown(-1);
          setLoginError(response.data.data.msg || '抱歉，连接错误');
        }
      } else {
        setCountDown(-1);
        setLoginError('抱歉，连接错误')
      }
    }).catch((error) => {
      store.setToken('');
      store.setUser('');
      if (!!error.response && !!error.response.data) {
        setCountDown(-1);
        setLoginError(error.response.data.msg || '抱歉，连接错误')
      } else {
        setCountDown(-1);
        setLoginError('抱歉，连接错误')
      }
    });
  };

  const close = (type) => {
    setShowLogin(false);
    if (typeof setIsShow === 'function') {
      setIsShow(false, type)
    }
    clearInterval(codeTimer);
  };

  return showLogin &&
    <section className="m-w-480 login-popup-box login-popup-verification-box">
      <div className="w-100p h-100p bg-c-000-78 login-popup-mask" />
      <div className="o-h bg-c-171b1fffffff login-popup">
        <div className="f-s-20 f-w-b l-h-14 c-ffffff191c27 t-a-c login-popup-title">验证</div>
        <div className={`f-s-14 l-h-142858 o-h login-popup-input-box ${!!nameInputError ? 'login-popup-input-error' : ''}`}>
          <input type="text" placeholder="手机号" autoComplete="off" ref={nameInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
          {!!nameInputError && <div className="f-s-12 c-d95757 login-popup-input-message">{nameInputError}</div>}
        </div>
        {
          type === 'password' &&
          <div className={`f-s-14 l-h-142858 o-h login-popup-input-box ${!!passwordInputError ? 'login-popup-input-error' : ''}`}>
            <input type="password" placeholder="密码" autoComplete="new-password" ref={passwordInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
            {!!passwordInputError && <div className="f-s-12 c-d95757 login-popup-input-message">{passwordInputError}</div>}
          </div>
        }
        {
          type === 'code' &&
          <div className={`f-s-14 l-h-142858 o-h login-popup-input-box login-popup-input-code-box ${!!codeInputError ? 'login-popup-input-error' : ''}`}>
            <input type="number" pattern="\d*" placeholder="验证码" ref={codeInput} className="w-100p h-100p c-ffffff191c27 bg-c-0f1214f8fafd login-popup-input" />
            <div className="d-f f-c-c h-100p c-ffffff1561f5 c-p login-popup-code" onClick={() => countDown <= 0 ? handleCodeConfirm() : {}}>{countDown <= 0 ? '获取验证码' : `已发送${countDown}秒`}</div>
            {!!codeInputError && <div className="f-s-12 c-d95757 login-popup-input-code-message">{codeInputError}</div>}
          </div>
        }
        <div className="f-s-12 c-6465669ea5bb t-a-c login-popup-tips">
          未注册手机验证后自动登录
          <div className="d-f f-c-c">
            <div className={`p-r ${policy ? 'bg-c-171b1f1561f5 b-c-2c38401561f5' : 'bg-c-171b1fffffff b-c-2c3840e8e8f3'} login-popup-checkbox-box`} onClick={() => setPolicy(!policy)}>
              {policy && <div className="b-c-1a73e8ffffff login-popup-checked" />}
            </div>
            <span>登录/注册即代表您已同意</span><a className="c-4f62ff1561f5 login-popup-tips-link" href="https://file.jingpt.com/agreement/privacy_policy_lanjingapp.html">隐私保护协议</a>
          </div>
        </div>
        {
          loginError && <div className="f-s-12 c-d95757 login-popup-error">{loginError}</div>
        }
        <div className="d-f f-b-c f-s-14 l-h-142858 login-popup-button-box">
          <div className="d-f f-c-c w-100p h-100p c-6465669ea5bb login-popup-button" onClick={() => close('close')}>
            取消
          </div>
          <div className="d-f f-c-c w-100p h-100p c-afb0b3191c27 login-popup-button" onClick={handleLoginConfirm}>
            确定
          </div>
        </div>
      </div>
    </section>
};

export default observer(Login);

