import React from 'react';
import {useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {getUseParams, setUrl} from '../utils/baseUtil';
import {dateDiff} from '../utils/dateUtil';

dayjs.locale('zh-cn');

const List = (props) => {
  const location = useLocation();
  const query = getUseParams(location.search);

  return Array.isArray(props.list) &&
    props.list.map((item) => {
      // 1电报 2深度文章 3稿库 4商讯
      const isShort = [1, 3, '1', '3'].includes(item.belong);

      let createTime = '';
      if (!!item.create_time) {
        createTime = dateDiff(item.create_time * 1000);
      }
      return (
        <div key={item.tid} className="d-f b-c-1e252be8e8f3 topic-dynamic-item">
          <a className="d-f w-100p" href={setUrl(isShort ? 'telegraph.html' : 'deepnews.html', query, item.tid)}>
            {
              Array.isArray(item.img_list) && !!item.img_list.length && !isShort &&
              <div className="f-s-0 topic-dynamic-item-img-box">
                <img alt="" src={item.img_list[0]} className="w-100p h-100p topic-dynamic-item-img"/>
              </div>
            }
            {
              props.type === 'person' && !!item.cover_img &&
              <div className="f-s-0 topic-dynamic-item-img-box">
                <img alt="" src={item.cover_img} className="w-100p h-100p topic-dynamic-item-img"/>
              </div>
            }
            <div className="f-g-1 d-f f-d-c f-b-s w-100p topic-dynamic-item-content">
              {/* {
                Array.isArray(item.labels) && !!item.labels.length &&
                <div className="d-f f-s-c f-s-12 c-c7c8d91561f5 l-h-166667 topic-dynamic-item-tag-box">
                  {
                    item.labels.map(labelItem => <div key={labelItem.id} className="bg-c-tebf1ff topic-dynamic-item-tag"># {labelItem.name}</div>)
                  }
                </div>
              } */}
              <div className="f-s-16 f-w-n-b c-ffffff191c27 l-h-125 t-o-e l-c-2 o-h topic-dynamic-item-content-desc">{!isShort ? (item.title || item.content || '') : (item.title ? `【${item.title}】${item.content}` : item.content)}</div>
              {
                Array.isArray(item.img_list) && !!item.img_list.length && isShort &&
                (
                  item.img_list.length === 1 ?
                    <div className="w-100p o-h topic-dynamic-item-telegraph-img-box">
                      <img alt="" src={item.img_list[0]} className="topic-dynamic-item-telegraph-img1"/>
                    </div> :
                    <div className="w-100p o-h">
                      {
                        item.img_list.map(itemImage => <img alt="" src={itemImage} className="topic-dynamic-item-telegraph-img2"/>)
                      }
                    </div>
                )
              }
              <div className="w-100p d-f f-b-c f-s-13 c-6465669395a2 l-h-125 topic-dynamic-item-content-action">
                <div className="o-h l-c-1 t-o-e topic-dynamic-item-from">{item.from_source} {createTime}</div>
                <div className="o-h l-c-1 t-o-e topic-dynamic-item-num">{item.view_num} 阅读</div>
              </div>
            </div>
          </a>
        </div>
      )
    })
};

export default List;