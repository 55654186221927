import React from 'react';
import {useLocation} from 'react-router-dom';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import dayjs from 'dayjs';
import SwiperStyle from '../style/SwiperStyle';
import 'dayjs/locale/zh-cn';
import {getUseParams, setUrl} from '../../utils/baseUtil';

dayjs.locale('zh-cn');

SwiperCore.use([Pagination, Autoplay]);

const Banner = (props) => {
  const location = useLocation();
  const query = getUseParams(location.search);

  return Array.isArray(props.list) && !!props.list.length &&
    <SwiperStyle className="banner-swiper-wrap" SwiperPagination>
      <Swiper
        className="o-h banner-swiper-box"
        loop={true}
        autoplay={{
          disableOnInteraction: false
        }}
        pagination={{ clickable: true }}
      >
        {
          props.list.map(item => {
            return (
              <SwiperSlide key={item.id}>
                <a href={setUrl('live.html', query, item.id)}><img src={item.img} alt="" className="w-100p h-100p o-f-c" /></a>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </SwiperStyle>
};

export default Banner;
