import React from 'react';
import {Link} from 'react-router-dom';
import {openApp} from '../utils/baseUtil';
import {dateDiff} from '../utils/dateUtil';

const League = (props) => {
  const detail = props.item || [];
  const type = props.type;

  return (
    <div className={`${type === 'list' ? 'p-r b-c-11141cf5f6fa article-main-list-box' : 'article-main-detail-box'} article-main-box`}>
      <div className="article-head-wrap">
        <div className="d-f f-s-c article-head-box">
          <div className="o-h default-avatar article-head-image-box">
            {!!detail.user_head_img && <img alt="" className="w-100p h-100p o-f-c" src={detail.user_head_img}/>}
          </div>
          <div className="f-1">
            <div className="l-c-1 t-o-e o-h f-s-14 f-w-n-b c-ffffff191c27 article-head-name">{detail.user_name}</div>
            <div className="l-c-1 t-o-e o-h f-s-13 c-565f719ea5bb article-head-company">
              {detail.user_company} {detail.user_position}
            </div>
          </div>
        </div>
      </div>

      <div className="article-content-box">
        <Link to={{
          pathname: 'article.html',
          search: `?id=${detail.id}`,
        }}>
          <div className={`f-s-18 f-w-b l-h-144444 c-ffffff191c27 m-b-10 ${type === 'list' ? 'o-h t-o-e l-c-5 article-content-introduce-list' : 'article-content-introduce'} `}>
            <span className="f-s-16 f-w-n-b c-1a8cff article-content-introduce-tag">{detail.resource_name}</span>
            {detail.description}
          </div>
        </Link>
        {
          Array.isArray(detail.image) && !!detail.image.length &&
          <div className="article-content-pictures">
            {
              detail.image.map((item, i) => (
                <img key={`${item}-${i}`} src={item} alt="" className="bg-c-fff article-content-pictures-item"/>
              ))
            }
          </div>
        }
        {
          Array.isArray(detail.industry) && !!detail.industry.length &&
          <div className="d-f f-s-s m-b-10 f-s-14 l-h-142858 article-content-industry">
            <div className="m-r-10 c-646566 article-content-industry-title">目标行业</div>
            <div className="c-191c27 f-w-n-b article-content-industry-tag">
              {
                detail.industry.map(item => item.name).join('、')
              }
            </div>
          </div>
        }
        {
          !!detail.company && (
            <div className="d-f f-s-s m-b-10 f-s-14 l-h-142858 article-content-company">
              <div className="m-r-10 c-646566 article-content-company-title">目标公司</div>
              <div className="c-191c27 f-w-n-b article-content-company-tag">{detail.company}</div>
            </div>
          )
        }
        {
          !!detail.coin && (
            <div className="d-f f-s-s f-s-14 l-h-142858">
              <span className="m-r-10 c-646566 article-content-action-brokerage">介绍佣金</span>
              <span className="d-f f-s-c c-4f62ff1561f5 article-content-action-coin">
                <img className="article-content-action-coin-icon" src="https://file.jingpt.com/wap/images/coin-icon.png" alt=""/>
                <span className="c-1a8cff f-w-n-b">{detail.coin} 蓝鲸币</span>
              </span>
            </div>
          )
        }
        <div className="d-f f-b-c f-s-13 c-6465669395a2 article-bottom-action">
          {detail.create_time && <div>{dateDiff(detail.create_time * 1000)}</div>}
          <div className="d-f f-b-c" onClick={openApp}>
            <img className="action-icon article-action-icon" src="https://file.jingpt.com/wap/images/like-icon.png" alt=""/>
            {Array.isArray(detail.like_list) && !!detail.like_list.length ? detail.like_list.length : ''}
            <img className="m-l-20 action-icon article-action-icon" src="https://file.jingpt.com/wap/images/comment-icon.png" alt=""/>
            评论
          </div>
        </div>
      </div>
      {
        ((Array.isArray(detail.like_list) && !!detail.like_list.length) || (Array.isArray(detail.comment_list) && !!detail.comment_list.length)) &&
        <div className="f-s-14 l-h-157143 bg-c-203051f5f6fa article-comment-box">
          {
            Array.isArray(detail.like_list) && !!detail.like_list.length &&
            <div className="d-f f-s-s article-comment-actions">
              <img className="action-icon article-action-icon" src="https://file.jingpt.com/wap/images/like-icon.png" alt=""/>
              <div className="c-ffffff191c27 f-w-n-b article-comment-actions-tag">
                {detail.like_list.map(item => item.name).join('，')}
              </div>
            </div>
          }
          {
            Array.isArray(detail.comment_list) && !!detail.comment_list.length &&
            <div className={`article-comment-reply ${(Array.isArray(detail.like_list) && !!detail.like_list.length) ? 'article-comment-reply-top' : ''}`}>
              {
                detail.comment_list.map((item, i) => {
                  return (
                    <div className="article-comment-reply-item" key={i}>
                      <span className="f-w-n-b c-191c27" onClick={openApp}>{item.from_user_name ? (!item.to_user_name ? `${item.from_user_name}：` : item.from_user_name) : ''}</span>
                      {
                        item.to_user_name &&
                        <>
                        <span className="c-565f71"> 回复 </span>
                        <span className="f-w-n-b c-191c27" onClick={openApp}>{`${item.to_user_name}：` || ''}</span>
                        </>
                      }
                      <span className="c-565f71">{item.content}</span>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      }
    </div>
  )
};

export default League;