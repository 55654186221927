import React from 'react';
import Avatar from './Avatar';
import {dateDiff} from '../../utils/dateUtil';
import {openApp} from '../../utils/baseUtil';

const Comment = (props) => {
  const item = props.item || [];

  return (
    <section className="b-m-w-16">
      <div className="f-s-18 f-w-b l-h-144444 c-ffffff0a0b10 comment-tip">热门评论</div>
      {
        Array.isArray(item) && !!item.length ?
          <>
          {
            item.map(answerItem => {
              return (
                <div key={answerItem.id} className="comment-box">
                  <div onClick={openApp}>
                    <Avatar size="small" item={answerItem || {}} nofollow={true} />
                  </div>
                  <div className="comment-title-box">
                    <div className="l-c-2 t-o-e o-h f-s-16 l-h-15 c-ffffff191c27 comment-title"><pre>{answerItem.content}</pre></div>
                    {!!answerItem.create_time && <div className="f-s-13 c-6465669395a2">编辑于{dateDiff(answerItem.create_time * 1000)}</div>}
                  </div>
                </div>
              )
            })
          }
          <div className="p-r f-s-13 l-h-15 c-1a8cff comment-title-more" onClick={openApp}>打开app查看更多评论</div>
          </>
          :
        <div className="m-b-30 f-s-14 l-h-142858 c-6465669ea5bb t-a-c">
          <div className="comment-list-empty-image" />
          还没有评论
        </div>
      }
    </section>
  )
};

export default Comment;