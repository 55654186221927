import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import MeScroll from 'mescroll.js';
import OpenApp from '../components/OpenApp';
import List from '../components/live/List';
import {getUseParams} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

let mescrollDom = null;
let page = '';
let list = [];

const StudentList = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [expertList, setExpertList] = useState([]);

  useEffect(() => {
    mescrollDom = initMescroll('body');

    if (!!query.id) {
      getData(page, true);
    }
  }, [query.id]);

  const initMescroll = (mescrollId) => {
    return new MeScroll(mescrollId, {
      down: {
        use: false,
      },
      up: {
        offset: 200,
        auto: false,
        warpClass: '',
        noMoreSize: 1,
        htmlLoading: '<div class="d-f f-c-c f-s-14 c-ffffff646566 student-list-interaction">加载中..</div>',
        htmlNodata: '<div class="d-f f-c-c f-s-14 c-ffffff646566 student-list-interaction">到底了</div>',
        showNoMore: (mescroll, upwarp) => {
          const listStatus = document.querySelector('.student-list-interaction');
          if (!!listStatus) {
            listStatus.innerHTML = '到底了';
          }
        },
        callback: () => {
          getData(page)
        },
        isBounce: false,
      },
    });
  };

  const getData = (page_no, hassetweixin) => {
    axios.get('/api/v1/live/learn', {
      timeout: 5000,
      params: {
        column: query.id,
        rn: 10,
        last_time_str: page_no
      }
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (typeof setWeixinData !== 'undefined' && response.data.code === 200 && response.data.data && !!hassetweixin) {
          setWeixinData(query.name || '和大咖面对面', '前瞻信息解读，知识系统梳理。在蓝鲸财经拓宽认知边界，结识业界大咖。', response.data.data.share_logo);
        }

        if (response.data.code === 200 && response.data.data && Array.isArray(response.data.data.webcast_list)) {
          const webcastList = response.data.data.webcast_list;
          if (webcastList.length > 0) {
            if (webcastList.length < 10) {
              mescrollDom.endSuccess(webcastList.length, false);
              mescrollDom.showNoMore();
            } else {
              mescrollDom.endSuccess(webcastList.length, true);
            }
            list = !page_no ? webcastList : list.concat(webcastList);
            setExpertList(list);
            page = webcastList[webcastList.length - 1].start_time_str || '';
          } else {
            if (!page_no) {
              list = '没有相关内容';
              setExpertList(list);
            }
            mescrollDom.endSuccess(0, false);
            mescrollDom.showNoMore();
            page = Array.isArray(list) && !!list.length ? list[list.length - 1].start_time_str : '';
          }
        } else {
          mescrollDom.endErr();
        }

      }
    }).catch((error) => {
      mescrollDom.endErr();
      const expertListLength = expertList.length;
      page = !!expertListLength ? expertList[expertListLength.length - 1].start_time_str : '';
    });
  };

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214f8fafd s-body" />
        <title>和大咖面对面</title>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/mescroll.min.css`} />
      </Helmet>
      <OpenApp />
      {
        Array.isArray(expertList) && !!expertList.length &&
        <section className="bg-c-171b1ff8fafd student-list-box">
          <List list={expertList} />
        </section>
      }
    </>
  );
};

export default StudentList;