import React, {Component} from 'react';
import Helmet from 'react-helmet';

class Index extends Component {
  render() {
    return (
      <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
      </Helmet>
      <div className="t-a-c page-404">
        <div className="page-404-image" src=""/>
        <div className="f-s-14 l-h-142858 c-646566">很抱歉，页面走丢了</div>
      </div>
      </>
    );
  }
}

export default Index;