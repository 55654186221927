import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import xss from 'xss';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from '../components/OpenApp';
import Avatar from '../components/qa/Avatar'
import QuestionList from '../components/qa/QuestionList';
import AudioPlayer from '../components/AudioPlayer';
import MutiClampText from '../components/MutiClampText';
import Comment from '../components/qa/Comment';
import {getUseParams, openApp} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

const Qa = (props) => {
  const isAnswer = props.isAnswer;

  const location = useLocation();
  const query = getUseParams(location.search);

  const [data, setData] = useState({});
  const [dataError, setDataError] = useState('');

  const audio = useRef({});
  const [currentAudioId, setCurrentAudioId] = useState(0);
  const audioProgress = useRef({});

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api2/proficient/qa/${query.id}`, {
        timeout: 5000,
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data && !!response.data.data.id) {
            const data = response.data.data;
            data.answers.forEach(item => {
              item.over_view = false
              if(!props.isAnswer && typeof item.content === 'string') {
                item.content = item.content.replace(/<br>/gi, '\r\n').replace(/<[^>]+>/gi, '').replace(/^[\r\n]+|[\r\n]+$/g,'');
              }
            });

            if (typeof setWeixinData !== 'undefined') {
              if (props.isAnswer) {
                let content = Array.isArray(data.answers) && data.answers[0] ? data.answers[0].content : ''
                const newAnswerContent = typeof content === 'string' ? content.replace(/<[^>]+>/gi, '') : content
                setWeixinData(data.title, newAnswerContent || '打开蓝鲸财经客户端，收听权威专家解读', data.share_logo);
              } else {
                setWeixinData(data.title, data.content || '打开蓝鲸财经客户端，收听权威专家解读', data.share_logo);
              }
            }

            setData(data);
          } else {
            setDataError('内容已下架/删除');
          }
        }
      }).catch((error) => {
      });
    }
  }, [query.id]);

  const audioSetDetail = (newList) => {
    if (Array.isArray(newList) && !!newList.length) {
      const firstData = Object.assign({}, newList[0] || {});
      setData(firstData);
    }
  };

  const audioSetExpertList = (newList) => {
    const newData = Object.assign({}, data);
    newData.answers = newList;
    setData(newData);
  };

  const handleOverView = (id, index) => {
    if (data.answers[index].id === id) {
      const newData = Object.assign({}, data);
      newData.answers[index].over_view = !newData.answers[index].over_view;
      setData(newData);
    }
  };

  return (
    <>
    <Helmet>
      <body className="bg-c-171b1fffffff s-body" />
      <title>{data.title || ''}</title>
    </Helmet>
    <OpenApp />
    {
      !!dataError ? <div className="f-s-14 t-a-c c-6465669ea5bb page-404">{dataError}</div> :
        <>
        {
          !isAnswer && <section className="b-m-w-16 question-top-box" onClick={openApp}>
            <Avatar size="small" item={data.creator || {}} nofollow={true} />
          </section>
        }
        <section className="b-m-w-16 w-b-a question-title-box">
          <div className="f-s-18 f-w-b l-h-144444 c-ffffff0a0b10 question-title" onClick={()=>{
            if(isAnswer) {
              openApp()
            }
          }}>{data.title}</div>
          {
            !isAnswer &&
            <>
              <div className="f-s-16 l-h-15 c-646566191c27 t-a-j question-content">
                {
                  data.content_type === 1 ?
                    <AudioPlayer
                      item={data}
                      expertList={[data]}
                      setExpertList={audioSetDetail}
                      currentAudioId={currentAudioId}
                      setCurrentAudioId={setCurrentAudioId}
                      audio={audio}
                      audioProgress={audioProgress}
                    /> :
                    <MutiClampText
                      className="f-s-16 l-h-15 c-646566191c27"
                      text={<div dangerouslySetInnerHTML={{__html: typeof data.content === 'string' ? xss(data.content) : null}} />}
                      line={6}
                      initOpen={true}
                      openIcon={<span className="d-f f-c-c clamp-icon clamp-open-icon">展开</span>}
                      closeIcon={<span className="d-f f-c-c clamp-icon clamp-close-icon">收起</span>}
                    />
                }
              </div>
              {
                !!data.create_time &&
                <div className="f-s-13 c-565f719ea5bb question-modify">
                  <div className="t-o-l">
                    编辑于{dayjs(data.create_time * 1000).format('YYYY-MM-DD HH:mm')}<span className="question-modify-icon">|</span>{(data.validity_time * 1000) > new Date().getTime() ? `采访将于${dayjs(data.validity_time * 1000).format('YYYY年MM月DD日 HH:mm')}结束` : '采访已结束'}
                  </div>
                </div>
              }
            </>
          }
        </section>
        {isAnswer && <div className={`d-f f-s-c b-p-w-16 p-r f-s-14 l-h-142858 c-9697995a6073 ${isAnswer ? 'question-list-number-answer' : 'question-list-number'}`} onClick={openApp}>回答 {data.count_answer || 0} {isAnswer && <span className="c-6f71885a6073 question-list-number-answer-icon">﹥</span>}</div>}
        {
          !isAnswer && <section className="p-f w-100p bg-c-fff c-afb0b39ea5bb b-c-1e252be8e8f3 question-toggle-box">
            {
              (data.validity_time * 1000) > new Date().getTime() ? 
              <div className="d-f f-b-c bg-c-1e252be9f3ff question-toggle-answer">
                <img className="question-audio-icon" src="https://file.jingpt.com/wap/images/question-audio-icon.png" onClick={openApp} alt=""/>
                <div className="f-s-16 c-565f71 bg-c-fff question-toggle-answer-input" onClick={openApp}>回答记者采访</div>
                <img className="question-picture-icon" src="https://file.jingpt.com/wap/images/question-picture-icon.png" onClick={openApp} alt=""/>
              </div> : 
              <div className="t-a-c f-s-16 c-fff bg-c-5e5e699ea5bb question-toggle-end">采访已结束</div>
            }
          </section>
        }
        {
          Array.isArray(data.answers) && !!data.answers.length ?
          <section className="question-list-content">
            {!isAnswer && <div className="b-p-w-16 p-r f-s-14 l-h-142858 c-9697995a6073 question-list-number">回答 {data.count_answer || 0}</div>}
            {
              isAnswer ? <QuestionList
                  item={data.answers[0] || {}}
                  showAll={true}
                  expertList={data.answers}
                  setExpertList={audioSetExpertList}
                  currentAudioId={currentAudioId}
                  setCurrentAudioId={setCurrentAudioId}
                  audio={audio}
                  audioProgress={audioProgress}
                /> :
              data.answers.map((item, index) => {
                return (
                  <QuestionList
                    key={item.id}
                    item={item || {}}
                    index={index}
                    showAll={false}
                    expertList={data.answers}
                    setExpertList={audioSetExpertList}
                    currentAudioId={currentAudioId}
                    setCurrentAudioId={setCurrentAudioId}
                    audio={audio}
                    audioProgress={audioProgress}
                    overView={handleOverView}
                  />
                )
              })
            }
          </section> :
          <section className="t-a-c question-empty">
            <div className="question-empty-image" />
            <div className="f-s-14 l-h-142858 c-6465669ea5bb studio-empty-live-text">暂无回答</div>
          </section>
        }
        {
          isAnswer && <Comment item={data.comment_list || []} />
        }
        </>
    }
    </>
  );
};

export default Qa;