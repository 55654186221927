import React from 'react';
import Helmet from 'react-helmet';
import OpenApp from '../components/OpenApp';
import {openApp} from '../utils/baseUtil';

const AuthenticationResult = () => {
  return (
    <>
      <Helmet>
        <body className="bg-c-171b1fffffff s-body" />
        <title>认证审核中...</title>
      </Helmet>
      <OpenApp />
      <section className="t-a-c">
        <div className="authentication-result-image" />
        <div className="f-s-14 l-h-157143 c-ffffff191c27 authentication-result-text">认证审核中…</div>
        <div className="f-s-12 l-h-15 c-6465669395a2 authentication-result-app">下载APP第一时间确认认证结果</div>
        <div className="f-s-16 f-w-b l-h-15 c-fff bg-c-d95757339aff-84bfff authentication-result-button" onClick={openApp}>立即下载</div>
      </section>
    </>
  );
};

export default AuthenticationResult;