import React from 'react';
import {useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {getUseParams, setUrl} from '../../utils/baseUtil';

dayjs.locale('zh-cn');

const List = (props) => {
  const location = useLocation();
  const query = getUseParams(location.search);

  return Array.isArray(props.list) && !!props.list.length &&
    props.list.map((listItem) => {
      const start_time = listItem.start_time_str || 0;
      const end_time = listItem.end_time || 0;
      let startTime = dayjs(Number((start_time).toString().substring(0, 13))).format('YYYY-MM-DD HH:mm');
      let endTime = '';
      if (end_time === 0) {
        endTime = '23:59'
      } else if (startTime.substring(0, 10) === endTime.substring(0, 10)) {
        endTime = endTime.substring(10)
      } else {
        endTime = dayjs(Number((end_time).toString().substring(0, 13))).format('YYYY-MM-DD HH:mm');
      }
      return (
        <a key={listItem.id} className="d-f b-c-1e252be8e8f3 live-index-item" href={setUrl('live.html', query, listItem.id)}>
          <div className="f-s-0 live-index-item-cover">
            {!!listItem.list_img && <img src={listItem.list_img} alt="" className="w-100p h-100p o-f-c"/>}
          </div>
          <div className="w-100p live-index-item-content">
            {listItem.status === 1 && <div className="f-s-11 c-f29130cca166 live-index-item-content-time">{`${startTime} - ${endTime}`}</div>}
            <div className="f-s-16 l-h-1375 c-ffffff191c27 t-o-e l-c-2 o-h">
              <span className={`f-s-12 f-w-b c-fff ${listItem.permission === 2 ? 'bg-c-339aff-84bfff' : listItem.permission === 1 ? 'bg-c-cb8839-f0d5af' : listItem.permission === 3 ? 'bg-c-ff75b0-fd4444' : ''} live-index-item-content-tag`}>
                {listItem.permission === 2 ? '邀约' : listItem.permission === 1 ? '免费' : listItem.permission === 3 ? '付费' : ''}
              </span>
              <span>{listItem.title}</span>
            </div>
            <div className="live-index-item-content-info">
              <div className="f-s-13 c-afb0b37e88a0 live-index-item-content-author">发布人：{listItem.creator_name}</div>
              {listItem.status !== 1 && <div className="f-s-11 c-6465669395a2 live-index-item-content-author">{startTime}</div>}
            </div>
          </div>
          <div className={`t-a-c f-s-12 c-fff ${['','bg-c-f29130b37000-ffdea8','bg-c-d95757ff75b0-fd4444','bg-c-5e5e699ea5bb','bg-c-1c288c39aff-84bfff'][listItem.status]} live-index-item-state`}>{['', '预 告', '进行中', '已结束', '回 放'][listItem.status]}</div>
        </a>
      )
    })
};

export default List;