import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from '../components/OpenApp';
import Banner from '../components/live/Banner';
import List from '../components/live/List';
import {setWeixinData} from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

const LiveIndex = () => {
  const [banner, setBanner] = useState([]);
  const [list1, setList1] = useState({status: 1});
  const [list2, setList2] = useState({status: 2});
  const [list3, setList3] = useState({status: 3});
  const [listMore, setListMore] = useState(['', '', '', '']);

  useEffect(() => {
    axios.get('/api/v1/live/banner/0', {
      timeout: 5000
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;
          if (Array.isArray(data) && !!data.length) {
            setBanner(data);
          }
        }
      }
    }).catch((error) => {

    });

    axios.get('/api/v1/live/home/webcast', {
      timeout: 5000
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;

          const newListMore = listMore.concat();
          if (Array.isArray(data) && !!data.length) {
            if (typeof setWeixinData !== 'undefined') {
              setWeixinData('发布会零距离接触', '聚焦市场热点，汇集名企资讯，尽在蓝鲸财经发布会。', data[0].share_logo);
            }

            data.forEach(item => {
              switch(item.status){
                case 1:
                  setList1(item);
                  break;
                case 2:
                  setList2(item);
                  break;
                case 3:
                  setList3(item);
                  break;
                default:
                  break;
              }
              if (Array.isArray(item.webcast_list) && item.webcast_list.length >= 3) {
                newListMore[item.status] = '查看更多';
              }
            })
          } else {
            if (typeof setWeixinData !== 'undefined') {
              setWeixinData('发布会零距离接触', '聚焦市场热点，汇集名企资讯，尽在蓝鲸财经发布会。', '');
            }
          }
          setListMore(newListMore);
        }
      }
    }).catch((error) => {

    });
  }, []);

  const getMore = (status) => {
    axios.get('/api/v1/live/webcast', {
      timeout: 5000,
      params: {
        status,
        last_time_str: status === 1 ? list1.webcast_list[list1.webcast_list.length-1].start_time_str : status === 2 ? list2.webcast_list[list2.webcast_list.length-1].start_time_str : status === 3 ? list3.webcast_list[list3.webcast_list.length-1].start_time_str : '',
        rn: 3
      }
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;
          const newListMore = listMore.concat();
          if (Array.isArray(data.webcast_list)) {
            switch(status){
              case 1:
                const newList1 = list1;
                newList1.webcast_list = newList1.webcast_list.concat(data.webcast_list);
                setList1(newList1);
                break;
              case 2:
                const newList2 = list2;
                newList2.webcast_list = newList2.webcast_list.concat(data.webcast_list);
                setList2(newList2);
                break;
              case 3:
                const newList3 = list3;
                newList3.webcast_list = newList3.webcast_list.concat(data.webcast_list);
                setList3(newList3);
                break;
              default:
                break;
            }
            if (data.webcast_list.length < 3) {
              newListMore[status] = '到底啦';
            }
          }
          setListMore(newListMore);
        }
      }
    }).catch((error) => {

    });
  };

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
        <title>发布会零距离接触</title>
      </Helmet>
      <OpenApp />
      {
        Array.isArray(banner) && !!banner.length &&
        <Banner list={banner} />
      }

      {
        [list2, list1, list3].map(item => {
          return Array.isArray(item.webcast_list) && !!item.webcast_list.length &&
            <section key={item.status} className="bg-c-171b1fffffff b-c-f8fafd live-index-box">
              <div className="f-s-20 f-w-600 c-ffffff333333 l-h-14 live-index-title">{item.status === 2 ? '发布会进行中' : item.status === 1 ? '发布会预告' : item.status === 3 ? '发布会历史' : ''}</div>
              <div>
                {
                  Array.isArray(item.webcast_list) && !!item.webcast_list.length && <List list={item.webcast_list} />
                }
              </div>
              {Array.isArray(listMore) && !!listMore[item.status] && <div className="d-f f-c-c b-c-1e252be8e8f3 f-s-13 c-6465669395a2 l-h-1375 live-index-more-box" onClick={() => {listMore[item.status] === '查看更多' && getMore(item.status) }}>{listMore[item.status]}</div>}
            </section>
        })
      }
    </>
  );
};

export default LiveIndex;