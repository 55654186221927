import { observable } from 'mobx';
import Cookies from 'js-cookie';
const cookieToken = Cookies.get('_LJWAP_TOKEN');
const cookieUser = Cookies.get('_LJWAP_USER');

const Store = observable({
  token: cookieToken || '',
  user: cookieUser || '',
  userInformation: {},
  setToken(value) {
    this.token = value;
  },
  setUser(value) {
    this.user = value;
  },
  setUserInformation(value) {
    this.userInformation = value;
  }
});

export default Store;