import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from '../components/OpenApp';
import Banner from '../components/live/Banner';
import List from '../components/live/List';
import SwiperStyle from '../components/style/SwiperStyle';
import {getUseParams, setUrl} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

const LiveIndex = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [banner, setBanner] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get('/api/v1/live/banner/1', {
      timeout: 5000
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {
          const data = response.data.data;
          if (Array.isArray(data) && !!data.length) {
            setBanner(data);
          }
        }
      }
    }).catch((error) => {

    });

    axios.get('/api/v1/live/home/learn', {
      timeout: 5000
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 200 && response.data.data) {

          if (typeof setWeixinData !== 'undefined') {
            setWeixinData('和大咖面对面', '前瞻信息解读，知识系统梳理。在蓝鲸财经拓宽认知边界，结识业界大咖。', response.data.data.share_logo);
          }

          setData(response.data.data)
        }
      }
    }).catch((error) => {

    });
  }, []);

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
        <title>和大咖面对面</title>
      </Helmet>
      <OpenApp />
      {
        Array.isArray(banner) && !!banner.length &&
        <Banner list={banner} />
      }

      {
        Array.isArray(data.learn_column_list) && !!data.learn_column_list.length &&
          <SwiperStyle className="bg-c-171b1fffffff student-index-navigation-box">
            <Swiper
              direction={'horizontal'}
              slidesPerView={4}
            >
              {
                data.learn_column_list.map(item => {
                  return (
                    <SwiperSlide
                      key={item.id}
                    >
                      <a key={item.id} className="d-f f-c-c f-w swiper-slide" href={setUrl('student_live_list.html', query, item.id)}>
                        <img src={item.icon} alt="" className="student-index-navigation-item-icon"/>
                        <div className="w-100p f-s-12 c-e6eaf20e131b l-h-15 t-a-c">{item.name}</div>
                      </a>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </SwiperStyle>
      }

      {
        Array.isArray(data.learn_column_webcast_list) && !!data.learn_column_webcast_list.length &&
        <section>
          {data.learn_column_webcast_list.map(item => {
            return Array.isArray(item.webcast_list) && !!item.webcast_list.length &&
              <div key={item.id} className="bg-c-171b1fffffff b-c-0f1214f8fafd student-index-column-list">
                <a href={setUrl('student_live_list.html', Object.assign(query, {name: item.name}), item.id)} className="d-f f-b-c f-s-20 f-w-b c-ffffff333333 l-h-14 o-h clearfix student-index-column-title">
                  <div>{item.name}</div>
                  <div className="f-g-1 d-f f-e-c f-s-12 c-6465669ea5bb l-h-15">
                    <span>查看全部</span>
                    <span className="student-index-column-title-icon">﹥</span>
                  </div>
                </a>
                <div>
                  {
                    Array.isArray(item.webcast_list) && !!item.webcast_list.length &&
                    <List list={item.webcast_list} />
                  }
                </div>
              </div>
            })
          }
        </section>
      }
    </>
  );
};

export default LiveIndex;