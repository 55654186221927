import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from './../components/OpenApp';
import PhotoSwipeCommon from '../components/PhotoSwipeCommon';
import { getUseParams } from '../utils/baseUtil';
import { setWeixinData } from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

const Telegraph = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    if (!!query.id) {
      axios.get('/api/v1/newsflash/detail', {
        timeout: 5000,
        params: {
          id: query.id
        },
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 200 && response.data.data) {
            const data = response.data.data;
            if (typeof setWeixinData !== 'undefined') {
              setWeixinData(data.title, data.content.substring(0, 20), '');
            }
            setDetail(data);
          }
        }
      }).catch((error) => {

      });
    }
  }, [query.id]);

  return (
    <>
      <Helmet>
        <body className="bg-c-192035f8fafd s-body" />
        <title>{detail.title || '蓝鲸财经'}</title>
      </Helmet>
      <OpenApp />
      <section className="telegraph-wrapper">
        {
          detail.title ? <div className="telegraph-head-box">
            {!!detail.r_time && <><span className="f-s-18 f-w-b c-fd4444 l-h-144444 telegraph-head-time">{dayjs(detail.r_time * 1000).format('HH:mm')}</span></>}
            <span className={`f-s-18 f-w-b l-h-144444 telegraph-head-title ${detail.is_red ? 'c-fd4444' : 'c-ffffff0a0b10'}`}>【{detail.title}】</span>
          </div> : null
        }
        <div className={`f-s-17 l-h-158824 telegraph-content ${detail.is_red ? 'c-fd4444' : 'c-ffffff0a0b10'}`} dangerouslySetInnerHTML={{ __html: (!detail.title && !!detail.r_time ? `<span class="m-r-10 f-s-18 f-w-b c-fd4444 l-h-144444 telegraph-head-time">${dayjs(detail.r_time * 1000).format('HH:mm')}</span>` : '') + (detail.content || '') }} />
        {
          Array.isArray(detail.img_list) && !!detail.img_list.length &&
          <div className="o-h telegraph-img-box">
            <PhotoSwipeCommon imagePhoto={detail.img_list}/>
          </div>
        }
        {Array.isArray(detail.labels) && !!detail.labels.length && <div className="f-s-12 c-c7c8d9646566 l-h-116667 telegraph-tag"># {detail.labels.map(labelItem => labelItem.name).join(' · ')}</div>}
        <div className="o-h f-s-12 c-6465669395a2 l-h-116667 telegraph-foot-box">
          {!!detail.r_time && <div className="f-l telegraph-foot-time">{dayjs(detail.r_time * 1000).format('YYYY-MM-DD HH:MM')}</div>}
          <div className="f-r"><span className="telegraph-foot-read-num">{detail.view_num}</span> 阅读</div>
        </div>
      </section>
    </>
  );
};

export default Telegraph;