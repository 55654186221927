import React, {Component} from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import Article from './pages/article.js'
import Deepnews from './pages/deepnews'
import Contact from './pages/contact'
import NoMatch from './components/NoMatch'
import Expert from './pages/expert'
import Live from './pages/live'
import LiveIndex from './pages/live_index'
import QaPerson from './pages/qa_person'
import QaPersonAuthentication from './pages/qa_person_authentication'
import QaPersonResult from './pages/qa_person_result'
import QaViewpoint from './pages/qa_viewpoint'
import QaQa from './pages/qa_qa'
import StudentLiveIndex from './pages/student_live_index'
import StudentLiveList from './pages/student_live_list'
import Telegraph from './pages/telegraph'
import Topic from './pages/topic'
import Explain from './pages/explain'
import PersonVideo from './pages/personVideo'
import Tag from './pages/tag.js'
import Collection from './pages/collection.js'
import Subject from './pages/subject.js'

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/resources/html/article.html" component={Article}/> /*互助*/
          <Route path="/resources/html/deepnews.html" component={Deepnews}/>
          <Route path="/resources/html/contact.html" component={Contact}/>
          <Route path="/resources/html/expert.html" component={Expert}/>
          <Route path="/resources/html/live.html" component={Live}/>
          <Route path="/resources/html/live_index.html" component={LiveIndex}/> /*已删除功能无用*/
          <Route path="/resources/html/qa_answer.html" render={() => <QaQa isAnswer={true} />}/>
          <Route path="/resources/html/qa_authentication.html" render={(history) => {
            const token = !!history && !!history.location && !!history.location.state && !!history.location.state.token ? history.location.state.token : '';
            const type = !!history && !!history.location && !!history.location.state && !!history.location.state.type ? history.location.state.type : '';
            const phone = !!history && !!history.location && !!history.location.state && !!history.location.state.phone ? history.location.state.phone : '';
            return !!token && !!type && !!phone ? <QaPersonAuthentication type={type} phone={phone} /> : <Redirect to={!!type ? `/resources/html/qa_${type}.html` : '/'} />
          }}/>
          <Route path="/resources/html/qa_proficient.html" render={() => <QaPerson type="proficient" />}/>
          <Route path="/resources/html/qa_question.html" render={() => <QaQa isAnswer={false} />}/>
          <Route path="/resources/html/qa_reporter.html" render={() => <QaPerson type="reporter" />}/>
          <Route path="/resources/html/qa_lanjing.html" render={() => <QaPerson type="lanjing" />}/>
          <Route path="/resources/html/qa_result.html" component={QaPersonResult}/>
          <Route path="/resources/html/qa_viewpoint.html" component={QaViewpoint}/>
          <Route path="/resources/html/student_live_index.html" component={StudentLiveIndex}/>
          <Route path="/resources/html/student_live_list.html" component={StudentLiveList}/>
          <Route path="/resources/html/telegraph.html" component={Telegraph}/>
          <Route path="/resources/html/topic.html" component={Topic}/>
          <Route path="/resources/html/explain.html" component={Explain}/>
          <Route path="/resources/html/person_video.html" component={PersonVideo}/>
          <Route path="/resources/html/collection.html" component={Collection}/>
          <Route path="/resources/html/tag.html" component={Tag}/>
          <Route path="/resources/html/subject.html" component={Subject}/>
          <Route component={NoMatch}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;