import wx from 'weixin-js-sdk'
import axios from "axios";

/**
 * 微信二次分享
 * @param name          标题
 * @param description   描述
 * @param image         封面图
 */

var setWeixin = function(name, description, image, menu) {
  axios.get("/api/v1/wx_ticket?url=" + encodeURIComponent(window.location.href)).then(function (res) {
    const data = res.data;
    if (data && data.data) {
      var weixin = {
        link: window.location.href,
        image: image || 'https://file.jingpt.com/wap/images/logo-share-default-830.png',
        title: name || '蓝鲸财经',
        descr: description || '专注深度报道，影响有影响力的人',
      }

      var v = data.data;
      if (typeof (wx) != 'undefined') {
        wx.config({
          // debug: true,
          appId: v.appId,
          timestamp: v.timestamp,
          nonceStr: v.nonceStr,
          signature: v.signature,
          jsApiList: menu,
          openTagList: ['wx-open-launch-app']
        });
        wx.ready(function () {
          if (menu.indexOf('hideMenuItems') > -1) {
            wx.hideMenuItems({
              menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:favorite', 'menuItem:copyUrl', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:qq', 'menuItem:share:QZone']
            });
          }
          // 发送给朋友
          if (wx.onMenuShareAppMessage) {
            wx.onMenuShareAppMessage({
              link: weixin.link,
              imgUrl: weixin.image,
              title: weixin.title,
              desc: weixin.descr,
              type: '', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: function () {
              },
              cancel: function () {
              }
            });
          } else {
            wx.updateAppMessageShareData({
              link: weixin.link,
              imgUrl: weixin.image,
              title: weixin.title,
              desc: weixin.descr,
              type: '', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: function () {
              },
              cancel: function () {
              }
            });
          }

          // 分享到朋友圈
          if (wx.onMenuShareTimeline) {
            wx.onMenuShareTimeline({
              link: weixin.link,
              imgUrl: weixin.image,
              title: weixin.title,
              success: function () {
              },
              cancel: function () {
              }
            });
          } else {
            wx.updateTimelineShareData({
              link: weixin.link,
              imgUrl: weixin.image,
              title: weixin.title,
              success: function () {
              },
              cancel: function () {
              }
            });
          }
        })
      }
    }
  });
}

export function setWeixinData(name, description, image) {
    setWeixin(name, description, image, ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'updateTimelineShareData'])
}

export function setWeixinHideMenuData(name, description, image) {
    setWeixin(name, description, image, ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems'])
}
