import React from 'react';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';

const PersonVideoList = (props) => {
  const detail = props.item || [];
  return (
    <div className="b-p-w-15 b-c-11141cf5f6fa video-album-list">
      <div className="f-s-17 l-h-141667 video-album-list-title">
        <Link to={{
          pathname: 'person_video.html',
          search: `?id=${detail.id}`,
        }}><div className="c-000">{detail.title}</div></Link>
      </div>
      <Link to={{
          pathname: 'person_video.html',
          search: `?id=${detail.id}`,
        }} className="d-b p-r video-album-list-image-wrap">
        <div className="p-a w-100p h-100p video-album-list-image-box">
          <img className="w-100p h-100p o-f-c" src={detail.list_img} alt=""/>
        </div>
      </Link>
      <div className="d-f f-b-c f-s-13 c-6465669395a2 article-bottom-action">
        {!!detail.ctime ? `编辑于${dayjs(detail.ctime * 1000).format("YYYY-MM-DD HH:mm")}` : ''}
      </div>
    </div>
  )
};

export default PersonVideoList;