import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import xss from 'xss';
import AudioPlayer from '../AudioPlayer';
import Avatar from './Avatar';
import {dateDiff} from '../../utils/dateUtil'
import {getUseParams, setUrl, openApp} from '../../utils/baseUtil';

const QuestionList = (props) => {
  const item = props.item || [];
  const index = props.index;
  const showAll = props.showAll;

  const location = useLocation();
  const query = getUseParams(location.search);

  return (
    <>
    <div className={`b-m-w-16 question-list-box ${showAll ? 'question-list-all-box' : ''}`}>
      {
        showAll ?
        <div className="question-list-avatar-answer-box" onClick={openApp}>
          <Avatar size="small" item={item.creator || {}} nofollow={true} />
        </div> :
        <Link to={`${setUrl('qa_answer.html', query, item.id)}`}>
          <div className="d-f f-s-c question-list-avatar-box">
            <img alt="" className="o-f-c question-list-avatar-image" src={(item.creator && item.creator.head_img) || "https://file.jingpt.com/wap/images/default_avatar-830.png"}/>
            <div className="d-f f-d-c f-c-s f-1">
              <div className="d-f question-list-base">
                {!!item.creator &&
                <div className="o-h l-c-1 t-o-e f-s-14 f-w-n-b c-ffffff191c27 question-list-name">{item.creator.real_name}</div>}
                <div className="d-f f-c-c o-h question-list-icon">
                  <img src="https://file.jingpt.com/wap/images/expert-237.png" alt=""/>
                </div>
              </div>
              <div className="f-g-1 o-h l-c-1 t-o-e f-s-13 c-6465669395a2 question-list-position">
                {item.creator && item.creator.company_name} {item.creator && (item.creator.title || item.creator.position)}
              </div>
            </div>
          </div>
        </Link>
      }
      <div className={`f-s-16 l-h-15 question-list-title ${showAll ? 'm-b-20' : 'question-list-title-item m-b-10'}`}>
        {
          item.content_type === 1 ?
            <AudioPlayer
              item={item}
              expertList={props.expertList}
              setExpertList={props.setExpertList}
              currentAudioId={props.currentAudioId}
              setCurrentAudioId={props.setCurrentAudioId}
              audio={props.audio}
              audioProgress={props.audioProgress}
            /> :
            (
              showAll ? <div className="c-afb0b3191c27 editor-text" dangerouslySetInnerHTML={{__html: typeof item.content === 'string' ? xss(item.content) : null}}/> :
                <div className={`c-afb0b3191c27 editor-text question-list-text ${item.over_view ? '' : 'o-h t-o-e l-c-2 question-list-title-text'}`} onClick={() => props.overView(item.id, index)} dangerouslySetInnerHTML={{__html: typeof item.content === 'string' ? xss(item.content) : null}}>
                </div>
            )
        }
      </div>
      {
        showAll ?
        <div className={`d-f f-b-c f-s-13 c-6465669395a2 question-list-tips`}>
          <div className="t-o-l">{!!item.create_time ? `编辑于${dateDiff(item.create_time * 1000)} ` : ' '}{typeof item.view_num === 'number' && item.view_num > 0 && `${item.view_num}人查看`}</div>
        </div> :
        <div className={`d-f f-b-c f-s-13 c-6465669395a2 question-list-tips-item`}>
          <div className="f-1 t-o-e l-c-1 o-h">
            {typeof item.view_num === 'number' && item.view_num > 0 && `${item.view_num}人查看`}
            {!!item.create_time && <span className="question-list-tips-time">{`编辑于${dateDiff(item.create_time * 1000)}`}</span>}
          </div>
          <a className="d-f f-c-c question-list-answer" href={setUrl('qa_answer.html', query, item.id)}>
            <img className="action-icon" src="https://file.jingpt.com/wap/images/comment-icon.png" alt=""/>
          </a>
        </div>
      }
    </div>
    </>
  )
};

export default QuestionList;