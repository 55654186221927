import { useEffect, useState, useRef } from "react";
export default (props) => {
    const { className, line = 3, text, openIcon, closeIcon, initOpen = false } = props;
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    const isXiaomi = /MI|REDMI|MIX|RC/i.test(u);
    const isAndroidQQ = (isAndroid && !isXiaomi && /MQQBrowser/i.test(u) && / QQ/i.test((u).split("MQQBrowser")));

    const [needShow, setNeedShow] = useState(false)
    const [maxHeight, setMaxHeight] = useState()
    const [lineHeight, setLineHeight] = useState()
    const [showAll, setShowAll] = useState(false)

    const textRef = useRef()
    const trueTextRef = useRef()

    const handleTextClick = () => {
        if(needShow) {
            setShowAll(!showAll)
        }
    }

    useEffect(()=>{
        if(text) {
            let trueLinHeight = window.getComputedStyle(trueTextRef.current).lineHeight || 20
            if(!isNaN(parseFloat(trueLinHeight))) {
                trueLinHeight = parseFloat(trueLinHeight)
            }
            setLineHeight(trueLinHeight)
            const maxHeight = line * trueLinHeight
            setMaxHeight(maxHeight)
            const trueHeight = trueTextRef.current.getBoundingClientRect().height
            if(trueHeight > maxHeight) {
                setNeedShow(true)
                if(initOpen) {
                    setShowAll(true)
                }
            }else {
                setNeedShow(false)
            }
        }
    }, [text])

    return (
        <div className="multi-clamp-wrapper" onClick={handleTextClick}>
            <div className={`line-clamp-${line} multi-clamp-text ${className || ''}`}  ref={textRef} style={{maxHeight: showAll ? 'none' : maxHeight + 'px', lineHeight: `${isAndroidQQ ? lineHeight * 1.13 : lineHeight}px`}}>
                <div className="multi-clamp-btn-before" style={{height: lineHeight ? `calc(100% - ${Math.round(isAndroidQQ ? lineHeight * 0.87 : isAndroid ? lineHeight * 0.97 : lineHeight)}px)` : 0}}></div>
                <label className={`multi-clamp-btn ${needShow ? '' : 'd-n'}`} style={{height: lineHeight + 'px', top: lineHeight ? isAndroidQQ ? `${(lineHeight*0.87 - 16)/2}px` : `${(lineHeight - 16)/2}px` : 0}}>
                   {
                        showAll ? 
                        <span className="close">{closeIcon || '收起'}</span> : 
                        <span className="open">{openIcon || '展开'}</span>
                   } 
                </label>
                <div className="multi-clamp-true-text" ref={trueTextRef}>{text}</div>
            </div>
        </div>
    )
}