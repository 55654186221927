import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Helmet from 'react-helmet'
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import OpenApp from './../components/OpenApp'
import InfiniteScroll from '../components/subject/InfiniteScroll'
import NoMatch from '../components/NoMatch'
import { getUseParams, setUrl } from '../utils/baseUtil'
import { setWeixinData } from '../utils/wxShareUtil'
import { dateDiff } from '../utils/dateUtil'

dayjs.locale('zh-cn')

const Subject = () => {
  const location = useLocation()
  const query = getUseParams(location.search)
  const [data, setData] = useState({})

  const [error, setError] = useState(false)

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api2/collection2/${query.id}`, {
        timeout: 5000
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data) {
            const data = response.data.data
            if (typeof setWeixinData !== 'undefined' && !!data.catalog) {
              setWeixinData(data.catalog.title, !!data.catalog.biref ? data.catalog.biref.replace(/[\n\r]/g, '') : '', data.share_logo)
            }
            setData(data)
          } else {
            setError(true)
          }
        } else {
          setError(true)
        }
      }).catch((error) => {
      })
    }
  }, [query.id])

  return (
    <>
      <Helmet>
        <body className="bg-c-fff s-body" />
        <title>{(data.catalog && data.catalog.title) || '专题'}</title>
      </Helmet>
      <OpenApp />
      {
        error ? <NoMatch /> :
          <div className="subject-wrapper">
            {
              !!data.catalog && !!data.catalog.img_detail &&
              <section className="p-r m-b-15 w-100p subject-cover-box">
                {
                  data.catalog.img_detail_link ? <a href={data.catalog.img_detail_link}>
                    <div className="p-a m-c w-100p h-100p">
                      <img alt="" src={data.catalog.img_detail} className="w-100p h-100p o-f-c" />
                    </div>
                  </a> : <div className="p-a m-c w-100p h-100p">
                    <img alt="" src={data.catalog.img_detail} className="w-100p h-100p o-f-c" />
                  </div>
                }
              </section>
            }
            {
              !!data.catalog && !!data.catalog.title &&
              <div className="b-m-w-15 m-b-12 o-h t-o-e l-c-3 l-h-133333 f-s-18 f-w-b c-000 subject-title-box">
                <span className="d-i-b p-r m-r-10 m-b-2 f-s-13 l-h-1 c-fff bg-c-32b4ff-2953ff subject-title-icon">
                  专题
                </span>
                <span className="subject-title-content">{data.catalog.title}</span>
              </div>
            }
            {!!data.catalog && !!data.catalog.brief && <section className="b-m-w-15 m-b-20 f-s-12 l-h-15 c-565f71 subject-brief">{data.catalog.brief}</section>}
            {
              !!data.catalog && Array.isArray(data.catalog.target_ids) && !!data.catalog.target_ids.length &&
              <section className="b-p-w-15 b-c-f4f6f8 subject-top-list-box">
                {
                  data.catalog.target_ids.map(item => {
                    return (
                      <a key={item.aid} className="d-b m-b-20" href={setUrl('deepnews.html', query, item.aid)}>
                        <div className="f-s-16 f-w-b l-h-1375 c-000 subject-top-list-title">{item.title}</div>
                        <div className="d-f f-b-c f-s-12 l-h-1 c-9ea5bb">
                          <div className="f-g-1">{item.creator || item.from_source || ''}</div>
                          <div className="f-s-0 d-f f-s-c">
                            <div>{dateDiff(item.modifyed_time * 1000)}</div>
                            {!!item.view_num && <div className="subject-top-list-read">{item.view_num}阅读</div>}
                          </div>
                        </div>
                      </a>
                    )
                  })
                }
              </section>
            }
            {
              Array.isArray(data.subjects) && !!data.subjects.length && <InfiniteScroll data={data.subjects} />
            }
          </div>
      }
    </>
  )
}

export default Subject