import React from 'react';
import {secondsToTime} from '../utils/baseUtil';

const AudioPlayer = (props) => {
  let item = props.item;
  if(typeof props.item.content_time === 'number' && props.item.content_time > 0) {
    item.duration = secondsToTime(props.item.content_time);
  }
  const expertList = props.expertList || [];
  const setExpertList = typeof props.setExpertList === 'function' ? props.setExpertList : () => {};
  const currentAudioId = props.currentAudioId || 0;
  const setCurrentAudioId = typeof props.setCurrentAudioId === 'function' ? props.setCurrentAudioId : () => {};
  const audio = props.audio;
  const audioProgress = props.audioProgress;

  const audioLoad = (item) => {
    const currentAudio = audio.current[item.id];
    const duration = secondsToTime(typeof item.content_time === 'number' && item.content_time > 0 ? item.content_time : (currentAudio.duration || 0));
    const newList = expertList.map(value => {
      const durationTime = value.id === item.id ? duration : typeof value.duration !== 'undefined' ? value.duration : '00:00';
      return Object.assign(value, {
        duration: durationTime,
        remainTime: durationTime,
      })
    });
    // list = newList;
    setExpertList(newList);
  };

  const audioTimeupdate = (item) => {
    const currentAudio = audio.current[item.id];
    const currentAudioProgress = audioProgress.current[item.id];
    const duration = item.content_time || currentAudio.duration;
    if (!isNaN(duration)) {
      //画进度条
      var progressValue = currentAudio.currentTime / duration; //用时间比来获取进度条的值
      if(progressValue >= 1){
        progressValue = 0;//当播放完成，进度条跳到开始
        setCurrentAudioId(0);
      }
      const newList = expertList.map(value => {
        return Object.assign(value, {
          currentTime: value.id === item.id ? secondsToTime(progressValue === 0 ? 0 : (currentAudio.currentTime || 0)) : typeof value.currentTime !== 'undefined' ? value.currentTime : '00:00',
          remainTime: value.id === item.id ? secondsToTime(progressValue === 0 ? duration : (currentAudio.currentTime === 0 ? duration : duration - currentAudio.currentTime)) : typeof value.remainTime !== 'undefined' ? value.remainTime : '00:00',
          width: value.id === item.id ? progressValue * 100 + '%' : !!value.width ? value.width : 0,
          left: value.id === item.id ? (progressValue * (currentAudioProgress.offsetWidth - 70) / currentAudioProgress.offsetWidth * 100 + '%') : !!value.left ? value.left : 0,
          waiting: value.id === item.id ? false : typeof value.waiting !== 'undefined' ? value.waiting : false
        })
      });
      // list = newList;
      setExpertList(newList);
    }
  };

  const audioEnded = (item) => {
    const currentAudio = audio.current[item.id];
    const duration = item.content_time === 'number' ? item.content_time : (currentAudio.duration || 0);
    setCurrentAudioId(0);
    const newList = expertList.map(value => {
      return Object.assign(value, {
        currentTime: value.id === item.id ? '00:00' : typeof value.currentTime !== 'undefined' ? value.currentTime : '00:00',
        remainTime: value.id === item.id ? secondsToTime(duration) : typeof value.remainTime !== 'undefined' ? value.remainTime : '00:00',
        width: value.id === item.id ? 0 : !!value.width ? value.width : 0,
        left: value.id === item.id ? 0 : !!value.left ? value.left : 0,
        waiting: value.id === item.id ? false : typeof value.waiting !== 'undefined' ? value.waiting : false
      })
    });
    // list = newList;
    setExpertList(newList);
  };

  const audioWaiting = (item) => {
    const newList = expertList.map(value => {
      return Object.assign(value, {
        waiting: value.id === item.id ? true : typeof value.waiting !== 'undefined' ? value.waiting : false
      })
    });
    // list = newList;
    setExpertList(newList);
  };

  const audioPlaying = (item) => {
    const newList = expertList.map(value => {
      return Object.assign(value, {
        waiting: value.id === item.id ? false : typeof value.waiting !== 'undefined' ? value.waiting : false
      })
    });
    // list = newList;
    setExpertList(newList);
  };

  const audioPlay = (item) => {
    if (currentAudioId !== 0) {
      if (currentAudioId !== item.id) {
        audio.current[currentAudioId].pause();
        setCurrentAudioId(item.id);
        audio.current[item.id].play();
      } else if (currentAudioId === item.id) {
        setCurrentAudioId(0);
        audio.current[item.id].pause();
      }
    } else {
      setCurrentAudioId(item.id);
      audio.current[item.id].play();
    }
  };

  const audioChangeTime = (e, item) => {
    const currentAudio = audio.current[item.id];
    const currentAudioProgress = audioProgress.current[item.id];
    const duration = item.content_time || currentAudio.duration;
    if(!isNaN(duration)){
      var theRealEvent = e.targetTouches[0];
      var currentTime = Math.round((duration * (theRealEvent.pageX - 70 - currentAudioProgress.offsetLeft)) / currentAudioProgress.offsetWidth);
      if (currentTime >= duration) {
        currentTime = duration;
      } else if (currentTime < 0) {
        currentTime = 0;
      }
      currentAudio.currentTime = currentTime;
    } else {
      currentAudio.currentTime = 0;
    }
  };

  return (
    <div className="audio-player-wrapper">
      <div className="d-f f-b-c bg-c-tf8fafd audio-player-box">
        <div className="p-r f-s-0 d-f f-c-c f-s-16 c-fff bg-c-d957572953ff audio-player-button" onClick={(e) => {
          e.stopPropagation()
          audioPlay(item)
        }}>
          <audio
            ref={(ref) => audio.current[item.id] = ref}
            className="audio-player-audio"
            controls
            preload="auto"
            src={item.content_url}
            onLoadStart={() => audioLoad(item)}
            onLoadedMetadata={() => audioLoad(item)}
            onLoadedData={() => audioLoad(item)}
            onTimeUpdate={() => audioTimeupdate(item)}
            onEnded={() => audioEnded(item)}
            onWaiting={() => audioWaiting(item)}
            onPlaying={() => audioPlaying(item)}
          />
          {item.waiting ? <div className="p-r audio-player-audio-waiting" /> : currentAudioId === item.id ? <div className="audio-player-audio-playing" /> : <div className="audio-player-audio-pause" />}
        </div>
        <div className="p-r f-g-1 audio-player-progress-box">
          <div className="audio-player-progress" ref={(ref) => audioProgress.current[item.id] = ref}>
            <div className="h-100p audio-player-progress-current" style={{width: item.width || 0}} />
          </div>
          <div className="f-s-12 l-h-16 c-fff audio-player-progress-button" style={{left: item.left || 0}} onTouchMove={(e) => audioChangeTime(e, item)}>
            <div className="t-o-l audio-player-time audio-player-time-current">{item.currentTime || '00:00'}</div>
            <div className="audio-player-time-line">/</div>
            <div className="t-o-r audio-player-time audio-player-time-duration">{item.duration || '00:00'}</div>
            <div className="audio-player-time audio-player-time-remain">{item.remainTime || '00:00'}</div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AudioPlayer;