import React, { useState } from 'react';
import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipe} from 'react-photoswipe';

function Gallery(props){
  const [items, setItems] =  useState(Array.isArray(props.imagePhoto) && !!props.imagePhoto.length ? props.imagePhoto.map(item => ({
    src: 'data:image/gif;base64,R0lGODlhEAAQALMNAD8/P7+/vyoqKlVVVX9/fxUVFUBAQGBgYMDAwC8vL5CQkP///wAAAP///wAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQJAAANACwAAAAAEAAQAAAEPbDJSau9OOvNew0AEHDA8wCkiW6g6AXHMU4LgizUYRgEZdsUggFAQCgUP+AERggcFYHaDaMgEBQchBNhiQAAIfkECQAADQAsAAAAABAAEAAABDuwyUmrvTYAEDAFzwN4EyiSksaRyHF06GEYBNoQ82EHBwHbCIUCYRMKiwSCYoFALDCIwLDZBFJtTKclAgAh+QQJAAANACwAAAAAEAAQAAAEPrDJGQAIM2vwHtAUcVTdBzaHYRCKip2EepxacBAvjSgKQmc83m+iILCGEkSgh5wsEIhFEwqdUpvPaHPLnUQAACH5BAkAAA0ALAAAAAAQABAAAAQ+sMkZyAkz62MM0ROiKAjRXQCATeOIHEQAPA+QKQShZHOdIQFSRqaSLBCIBQiERC41TcQzc0xOr9isdsvtPiMAIfkECQAADQAsAAAAABAAEAAABD2wyYmUQjNra/VcCLIoBKEExBFkYRtcBGAQbJsdhnFkoMimGI8wAACshBnA4wFAJpdNp4RolFqv2Kx2q4kAACH5BAkAAA0ALAAAAAAQABAAAAQ9sMm5EFoza2u1b5ylKMjXVFdAjGamrEo7IWMpz8QR3A0BGATewWA48BA5mykAAOxugMcDwItOeUwnb9uKAAAh+QQJAAANACwAAAAAEAAQAAAEO7DJSau92C6EVp4c90khMjZbd5KKYo4B0Z4KIZ9I4H7IQQSng8FwwAQAgJgBQMAAHo+kD3h5Rk/HpCUCACH5BAkAAA0ALAAAAAAQABAAAAQ8sMlJq7046827nwuCLJwoliYXjlIAAAGFKApCAc8DULQSTzgd4kCYEQgKigt2MBgOC5rtQnAeOAHilBIBADs=',
    w: 8,
    h: 8,
  })):[]);

  const [photoOpen, setPhotoOpen] = useState({
    open: false,
    index: 0
  });

  const loadImage = ({e, index})=>{
    const availWidth = document.documentElement.clientWidth;
    const availHeight = document.documentElement.clientHeight;
    let getWHItems = {w: e.target.naturalWidth, h:e.target.naturalHeight};

    if (e.target.naturalWidth > e.target.naturalHeight) {
      if (e.target.naturalWidth < availWidth) {
        getWHItems.w = e.target.naturalWidth;
        getWHItems.h = e.target.naturalHeight;
      } else {
        getWHItems.w = availWidth;
        getWHItems.h = availWidth * e.target.naturalHeight / e.target.naturalWidth;
      }
    } else {
      if (e.target.naturalHeight < availHeight) {
        getWHItems.w = e.target.naturalWidth;
        getWHItems.h = e.target.naturalHeight;
      } else {
        getWHItems.w = availHeight * e.target.naturalWidth / e.target.naturalHeight;
        getWHItems.h = availHeight;
      }
    }

    let newImagePhoto = items[index];
    if (!!items[index]) {
      newImagePhoto.src = e.target.src;
      newImagePhoto.w = getWHItems.w;
      newImagePhoto.h = getWHItems.h;
    }
    const newItem = items;
    newItem[index] = newImagePhoto;
    setItems(newItem)
  };

  const handleSetPhotoOpen = (status, index) => {
    setPhotoOpen({
      open: status,
      index: index || 0
    })
  };

  return (
    <div id={`img-container-${props.index}`} className="d-f f-s-c f-w">
      {
        Array.isArray(props.imagePhoto) && !!props.imagePhoto.length && props.imagePhoto.map((item, index) => {
          return (
            <div key={`${item}-${index}`} className={`f-s-0 ${index > 2 ? 'd-n' : ''} p-r live-multigraph-image-list`}>
              <img className="w-100p h-100p o-f-c live-multigraph-image" alt="" src={item} onLoad={(e)=>{loadImage({e, index})}} onClick={() => handleSetPhotoOpen(true, index)} />
              {
                index === 2 && props.imagePhoto.length > 3 &&
                <div className="d-f f-s-c f-s-12 l-h-166667 c-afb0b3 bg-c-f8fafd live-multigraph-tag">
                  <div className="d-f f-c-c live-multigraph-icon">
                    <svg width="14" height="14" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <rect stroke="#C7C8D9" fill="transparent" strokeWidth="1.5" x="0.75" y="0.75" width="16.5" height="16.5" rx="2" />
                      <polyline stroke="#C7C8D9" fill="transparent" points="1 15 6 9.5 9.5 13 17 5" />
                      <circle stroke="#C7C8D9" fill="transparent" cx="5" cy="5" r="1.5" />
                    </svg>
                  </div>
                  <div>{props.imagePhoto.length}</div>
                </div>
              }
            </div>
          )
        })
      }
      {
        Array.isArray(items) && !!items.length &&
        <PhotoSwipe
          isOpen={photoOpen.open}
          items={items}
          options={{
            history: false,
            focus: false,
            showAnimationDuration: 0,
            hideAnimationDuration: 0,

            zoomEl: false,
            arrowEl: false,
            clickToCloseNonZoomable: true,

            index: typeof photoOpen.index === 'number' ? photoOpen.index : 0,

            loop: false,
            pinchToClose: false,
            closeOnVerticalDrag: false,
            mouseUsed: true,
            errorMsg: '<div class="pswp__error-msg"><div class="default-background w-100p" style="height:200px"></div></div>',
            closeEl: false,
            shareEl: false,
            fullscreenEl: false,
            tapToClose: true,
          }}
          onClose={() => handleSetPhotoOpen(false)}
        />
      }

    </div>
  );
}

export default Gallery;