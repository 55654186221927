import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {getUseParams} from '../utils/baseUtil';
import League from './../components/League';
import OpenApp from './../components/OpenApp';
import {setWeixinData} from '../utils/wxShareUtil';

dayjs.locale('zh-cn');

const Article = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api/v1/league/${query.id}`, {
        timeout: 5000,
        params: {
          os: 'web',
          sv: query.sv,
        }
      }).then((res) => {
        if (res.data.code === 200 && res.data.data) {
          const data = res.data.data;
          setDetail(data);
          if (typeof setWeixinData !== 'undefined') {
            setWeixinData(`分享一条${data.user_company || ''}${data.user_position || ''}${data.user_name || ''}发布的互助联盟信息`, data.description, data.share_logo);
          }
        }
      }).catch((error) => {
      });
    }
  }, [query.id, query.os, query.sv]);

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
        <title>{`${detail.user_company || ''}${detail.user_position || ''}${detail.user_name || ''}发布的互助联盟信息`}</title>
      </Helmet>
      <OpenApp/>
      <section className="b-m-w-16">
        <League item={detail} type="detail" />
      </section>
    </>
  );
};

export default Article;