import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from './../components/OpenApp';
import InfiniteScroll from '../components/collection/InfiniteScroll'
import NoMatch from '../components/NoMatch'
import {getUseParams, setUrl} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';
import {dateDiff} from '../utils/dateUtil'

dayjs.locale('zh-cn');

const Collection = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [data, setData] = useState({});

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api2/collection/${query.id}`, {
        timeout: 5000
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data) {
            const data = response.data.data;
            if (typeof setWeixinData !== 'undefined' && !!data.catalog) {
              setWeixinData(data.catalog.title, !!data.catalog.biref ? data.catalog.biref.replace(/[\n\r]/g, '') : '', data.share_logo);
            }
            setData(data);
          } else {
            setError(true)
          }
        } else {
          setError(true)
        }
      }).catch((error) => {
      });
    }
  }, [query.id]);

  return (
    <>
    <Helmet>
      <body className="bg-c-fff s-body" />
      <title>{(data.catalog && data.catalog.title) || '专题'}</title>
    </Helmet>
    <OpenApp />
    {
      error ? <NoMatch/> :
      <>
      <section className="p-r m-b-15 w-100p collection-cover-wrap">
        {
          !!data.catalog && !!data.catalog.img_detail &&
          <div className="p-a m-c w-100p h-100p">
            <img alt="" src={data.catalog.img_detail} className="w-100p h-100p o-f-c"/>
          </div>
        }
        <div className="p-a m-c w-100p h-100p bg-c-000000-000000" />
        {
          !!data.catalog && !!data.catalog.title &&
          <div className="p-a o-h f-s-16 l-h-15 f-w-b c-fff collection-cover-content">
            <span className="d-i-f m-r-15 f-s-13 l-h-1 bg-c-32b4ff-2953ff collection-cover-content-icon">
              专题
            </span>
            <span className="collection-cover-content-title">{data.catalog.title}</span>
          </div>
        }
      </section>
      {!!data.catalog && !!data.catalog.brief && <section className="b-m-w-14 m-b-20 f-s-12 l-h-15 c-565f71">{data.catalog.brief}</section>}
      {
        !!data.catalog && Array.isArray(data.catalog.target_ids) && !!data.catalog.target_ids.length &&
        <section className="b-p-w-14 b-c-f4f6f8 collection-top-list-box">
          {
            data.catalog.target_ids.map(item => {
              return (
                <a key={item.aid} className="d-b m-b-20" href={setUrl('deepnews.html', query, item.aid)}>
                  <div className="o-h f-s-16 f-w-b l-h-1375 c-000 t-o-e l-c-2 collection-top-list-title">{item.title}</div>
                  <div className="d-f f-b-c f-s-12 l-h-1 c-9ea5bb">
                    <div className="f-g-1">{item.creator}</div>
                    <div className="f-s-0 d-f f-s-c">
                      <div>{dateDiff(item.modifyed_time * 1000)}</div>
                      {!!item.view_num && <div className="collection-top-list-read">{item.view_num}阅读</div>}
                    </div>
                  </div>
                </a>
              )
            })
          }
        </section>
      }
      {
        Array.isArray(data.subjects) && !!data.subjects.length && <InfiniteScroll data={data.subjects}/>
      }
      </>
    }
    </>
  );
};

export default Collection;