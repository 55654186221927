import React, {useEffect, useState} from 'react';
import {PhotoProvider,PhotoSlider} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const View = (props) => {
  const [imageItem, setImageItem] = useState([]);
  const [visible, setVisible] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    if (Array.isArray(props.item)) {
      setImageItem(props.item);
    }
  }, [props.item]);

  useEffect(() => {
    if (typeof props.index === 'number') {
      setIndex(props.index);
      setVisible(true);
    }
  }, [props.index]);

  return (
    <PhotoSlider
      pullClosable={false}
      images={imageItem.map((item, index) => ({ src: item, key: `${item}-${index}` }))}
      visible={visible}
      onClose={() => setVisible(false)}
      index={index}
      onIndexChange={setIndex}
      afterClose={() => props.handleOpenImage(null)}
    />
  )
}

export default View