import React, { useState, useEffect, useRef } from 'react';
import 'intersection-observer';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperStyle from '../../components/style/SwiperStyle';
import { getUseParams, setUrl } from '../../utils/baseUtil';
import { dateDiff } from '../../utils/dateUtil'

let page = 2
const pageSize = 20
const permissionMap = {
  1: { label: '免费', bgColor: 'bg-c-84bfff-339aff' },
  2: { label: '邀约', bgColor: 'bg-c-84bfff-339aff' },
  3: { label: '付费', bgColor: 'bg-c-cb8839-f0d5af' }
}
const InfiniteScroll = (props) => {
  const location = useLocation();
  const query = getUseParams(location.search);

  const [data, setData] = useState(props.data);
  const [isFixed, setIsFixed] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(0);
  const [list, setList] = useState([]);
  const [moreResult, setMoreResult] = useState('');

  const navRef = useRef(null);
  const swiperRef = useRef(null);
  const observer = useRef(null);
  const observerTarget = useRef(null);

  useEffect(() => {
    if (!!props.data[0] && Array.isArray(props.data[0].result_list)) {
      setList(props.data[0].result_list);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !moreResult) {
        let article = data[navActiveIndex].result_list;
        getMoreItem().then((newArticle) => {
          page = page + 1
          if (newArticle.length > 0) {
            const newList = [...list, ...newArticle];
            setList(newList);
            article = newList;
            data[navActiveIndex].result_list = article;
            setData(data);
            if (newArticle.length < pageSize) {
              setMoreResult('没有更多了');
            }
          } else {
            setMoreResult('没有更多了');
          }
        }).catch(error => {
          setMoreResult(error);
        });
      }
    }, {
      root: null,
      rootMargin: '0px 0px 50px 0px',
      threshold: 0
    });

    if (observerTarget.current) {
      observer.current.observe(observerTarget.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [moreResult, list]);

  useEffect(() => {
    if (!!props.data[navActiveIndex] && Array.isArray(props.data[navActiveIndex].result_list)) {
      setList(props.data[navActiveIndex].result_list);
    } else {
      setList([]);
    }
    page = 2
    setMoreResult('');
  }, [navActiveIndex]);

  const handleScroll = () => {
    if (navRef.current) {
      const navRect = navRef.current.getBoundingClientRect();
      setIsFixed(navRect.top - 50 <= 0);
    }
  };

  const getMoreItem = async () => {
    return new Promise((resolve, reject) => {
      axios.get('/api2/collection2/more', {
        timeout: 5000,
        params: {
          topic_id: data[navActiveIndex]?.topic_id,
          page_num: page,
          page_size: pageSize
        }
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data) {
            resolve(response.data.data)
          } else {
            reject('加载更多失败')
          }
        } else {
          reject('加载更多失败')
        }
      }).catch(() => {
        reject('加载更多失败')
      });
    });
  };

  const setTelegraphItem = (item) => {
    return <a key={item.tid} className="d-f b-m-w-14 m-t-20 m-b-20" href={setUrl('telegraph.html', query, item.tid)}>
      <div className="f-g-1 d-f f-d-c o-h">
        <div className="f-g-1 m-b-20">
          <div className="o-h f-s-16 f-w-b l-h-1375 c-191c27 t-o-e l-c-3">{item.title || item.content || ''}</div>
        </div>
        <div className="d-f f-b-c o-h l-h-1">
          {/* <div className="f-g-1 d-f f-s-c o-h">
            <div className="o-h f-s-13 c-9ea5bb w-s-n t-o-el">{item.creator || item.from_source || ''}</div>
          </div> */}
          <div className="f-s-0 d-f f-e-c w-100p f-s-12 c-9ea5bb">
            <div>{dateDiff(item.create_time * 1000)}</div>
            {!!item.view_num && <div className="subject-top-list-read">{item.view_num}阅读</div>}
          </div>
        </div>
      </div>
    </a>
  }

  const setArticleItem = (item) => {
    return <a key={item.tid} className="d-f b-m-w-14 m-t-20 m-b-20" href={setUrl('deepnews.html', query, item.tid)}>
      <div className="f-g-1 d-f f-d-c o-h">
        <div className="f-g-1 m-b-20">
          <div className="o-h f-s-16 f-w-b l-h-1375 c-191c27 t-o-e l-c-2">{item.title}</div>
        </div>
        <div className="d-f f-b-c o-h l-h-1">
          <div className="f-g-1 d-f f-s-c o-h">
            <div className="o-h f-s-13 c-9ea5bb w-s-n t-o-el">{item.creator || item.from_source || ''}</div>
          </div>
          <div className="f-s-0 d-f f-s-c f-s-12 c-9ea5bb">
            <div>{dateDiff(item.create_time * 1000)}</div>
            {!!item.view_num && <div className="subject-top-list-read">{item.view_num}阅读</div>}
          </div>
        </div>
      </div>
      {
        Array.isArray(item.img_list) && item.img_list[0] &&
        <div className="f-s-0 m-l-10 o-h collection-list-image">
          <img className="w-100p h-100p o-f-c" src={item.img_list[0]} alt="" />
        </div>
      }
    </a>
  }

  //1:未开始 2:直播中 3:直播结束 4:回放
  const statusIcon = {
    1: 'live-advance-835.png',
    2: 'live-ing-835.png',
    3: 'live-end-835.png',
    4: 'live-playback-835.png',
  }
  const setLiveItem = (item) => {
    return <a key={item.tid} className="d-f b-m-w-14 m-t-20 m-b-20" href={setUrl('live.html', query, item.tid)}>
      <div className="f-g-1 d-f f-d-c o-h">
        <div className="f-g-1 m-b-20">
          <div className="o-h f-s-16 f-w-b l-h-1375 c-191c27 t-o-e l-c-2">{item.title}</div>
        </div>
        <div className="d-f f-b-c o-h l-h-1">
          {
            statusIcon[item.status] ? <div className={`f-s-0 o-h live-list-icon ${item.status === 2 ? 'live-list-icon-ing' : ''}`}>
              <img className="w-100p h-100p o-f-c" src={`https://file.jingpt.com/wap/images/${statusIcon[item.status]}`} alt="" />
            </div> : null
          }
          <div className="f-g-1 t-a-r w-s-n o-h f-s-12 c-9ea5bb">
            {dayjs(item.create_time * 1000).format('YYYY-MM-DD HH:mm')}{item.end_time && (dayjs(item.create_time * 1000).format('YYYY-MM-DD') === dayjs(item.end_time * 1000).format('YYYY-MM-DD')) ? `-${dayjs(item.end_time * 1000).format('HH:mm')}` : ''}
          </div>
        </div>
      </div>
      {
        Array.isArray(item.img_list) && item.img_list[0] &&
        <div className="p-r f-s-0 m-l-10 o-h collection-list-image">
          <img className="w-100p h-100p o-f-c" src={item.img_list[0]} alt="" />
          <div className={`p-a t-a-c f-s-9 f-w-b c-fff ${permissionMap[item.permission]?.bgColor || ''} live-list-permission-tag`}>
            {permissionMap[item.permission]?.label || ''}
          </div>
        </div>
      }
    </a>
  }

  return (
    <>
      <section ref={navRef} className="p-r collection-nav-wrap">
        <SwiperStyle className={`${isFixed ? 'p-f m-w-480' : 'p-a m-c'} w-100p bg-c-fff collection-nav-box ${isFixed ? 'collection-nav-fixed-box' : ''}`}>
          <Swiper
            ref={swiperRef}
            className="o-h b-c-f5f5f5 collection-swiper-container"
            slidesPerView="auto"
            spaceBetween={20}
            resistanceRatio={0}
            initialSlide={0}
          >
            {
              props.data.map((item, index) => {
                return (
                  <SwiperSlide
                    className={`p-r d-f f-s-c f-s-16 l-h-1 collection-nav ${navActiveIndex === index ? 'collection-nav-active' : ''}`}
                    key={item.topic_id}
                    onClick={() => {
                      if (!!swiperRef.current) {
                        swiperRef.current.swiper.slideTo(index, 0, false);
                        setNavActiveIndex(swiperRef.current.swiper.clickedIndex)
                      }
                    }}
                  >
                    {item.title}
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </SwiperStyle>
      </section>
      <section>
        {
          !!list.length ?
            <>
              {
                list.map((item, index) => {
                  if (item.belong === 1) {
                    return setTelegraphItem(item)
                  }
                  if (item.belong === 5) {
                    return setLiveItem(item)
                  }
                  return setArticleItem(item)
                })
              }
              {
                !moreResult ? <div ref={observerTarget} /> : <div className="f-s-14 l-h-2 c-9ea5bb t-a-c">{moreResult}</div>
              }
            </> :
            <div>
              <div className="collection-list-empty" />
              <div className="f-s-14 l-h-157143 c-9ea5bb t-a-c">暂无内容</div>
            </div>
        }
      </section>
    </>
  );
};

export default InfiniteScroll;