import React from 'react';
import Helmet from 'react-helmet';

const Contact = () => {
  return (
    <>
      <Helmet>
        <body className="t-a-c" />
        <title>联系我们</title>
      </Helmet>
      <img alt="" src="https://file.jingpt.com/wap/images/contact.png" style={{width: '95%'}} />
    </>
  );
};

export default Contact;