import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css';
import OpenApp from './../components/OpenApp';
import Avatar from './../components/qa/Avatar';
import View from './../components/deepnews/PhotoView';
import { getUseParams, openApp, setUrl } from '../utils/baseUtil';
import { setWeixinData } from '../utils/wxShareUtil';
import { dateDiff } from '../utils/dateUtil';

dayjs.locale('zh-cn');

const Deepnews = () => {
  const location = useLocation();
  const query = getUseParams(location.search);
  const [detail, setDetail] = useState({});

  const [imageItem, setImageItem] = useState([]);
  const [index, setIndex] = React.useState(null);

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api2/deep_news/${query.id}`, {
        timeout: 5000
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data) {
            const data = response.data.data;
            if (!!data.content) {
              data.content = replaceImgsWithVideo(data.content);
            }
            if (typeof setWeixinData !== 'undefined') {
              setWeixinData(data.title, data.abstract_info, data.share_logo);
            }
            setDetail(data);
          }
        }
      }).catch((error) => {

      });
    }
  }, [query.id]);

  useEffect(() => {
    if (!!detail.content) {
      const image = document.querySelectorAll('.deepnews-content-report img');
      if (!!image) {
        const item = [];
        for (let i = 0; i < image.length; i++) {
          if (!!image[i].src || !!image[i].getAttribute('url') || !!image[i].getAttribute('data-url')) {
            const src = image[i].src || image[i].getAttribute('url') || image[i].getAttribute('data-url');
            item.push(src);
            image[i].addEventListener('click', () => {
              handleOpenImage(i)
            });
          }
        }
        setImageItem(item)
      }

      const ljVideo = document.querySelectorAll('.deepnews-content-report .lj_video_player');
      if (!!ljVideo) {
        for (let i = 0; i < ljVideo.length; i++) {
          const url = ljVideo[i].getAttribute('data-url');
          const cover = ljVideo[i].getAttribute('data-cover');
          const id = ljVideo[i].getAttribute('id');
          new Player({
            id: id,
            url: url,
            'x5-video-player-type': 'h5',
            cssFullscreen: false,
            playbackRate: false,
            playsinline: true,
            width: '100%',
            height: '100%',
            videoFillMode: 'contain',
            poster: cover
          });
        }
      }
      return () => {
        const image = document.querySelectorAll('.deepnews-content-report img');
        if (!!image) {
          for (let i = 0; i < image.length; i++) {
            if (!!image[i].src || !!image[i].getAttribute('url') || !!image[i].getAttribute('data-url')) {
              image[i].removeEventListener('click', () => { });
            }
          }
        }
      };
    }

  }, [detail.content]);

  const replaceImgsWithVideo = (htmlString) => {
    const regex = /<img\s+[^>]*data-cover=["\'](.*?)["\'][^>]*data-site=["\'](.*?)["\'][^>]*data-url=["\'](.*?)["\'][^>]*data-vid=["\'](.*?)["\'][^>]*>/gi;
    const iframeTemplate = '<div class="p-r w-100p lj-video-wrapper"><div class="p-a w-100p h-100p lj-video-box"><iframe class="w-100p h-100p" src="$3" frameborder="0" allowfullscreen="true"></iframe></div></div>';
    const videoTemplate = '<div class="p-r w-100p lj-video-wrapper"><div class="p-a w-100p h-100p lj-video-box"><div class="lj_video_player" id="$4" data-cover="$1" data-url="$3"></div></div></div>';

    // 使用正则表达式进行替换
    const replacedHtml = htmlString.replace(regex, (match, dataCoverValue, dataSiteValue, dataUrlValue, dataVidValue, index) => {
      if (dataSiteValue === 'lanjing') {
        return videoTemplate.replace('$1', dataCoverValue).replace('$3', dataUrlValue).replace('$4', 'lanjing_vid_' + dataVidValue + '' + index);
      } else if (['youku', 'qq', 'iqiyi', 'bilibili'].includes(dataSiteValue)) {
        return iframeTemplate.replace('$3', dataUrlValue);
      }
      return match
    });

    return replacedHtml;
  }

  const handleOpenImage = (i) => {
    setIndex(i)
  }

  return (
    <>
      <Helmet>
        <body className="bg-c-0f1214ffffff s-body" />
        <title>{detail.title || '要闻详情'}</title>
      </Helmet>
      <OpenApp />
      {
        <section>
          {/* {
            !!detail.cover_img ?
            <div className="p-r deepnews-cover-wrap">
              <div className="p-a m-c w-100p h-100p">
                <img alt="" src={detail.cover_img} className="w-100p h-100p o-f-c"/>
              </div>
              <div className="w-100p h-100p deepnews-cover-mask" />
              {
                detail.title && <div className="p-a f-s-17 l-h-152941 f-w-b c-fff w-b-a deepnews-cover-content">{detail.title}</div>
              }
            </div> : detail.title ? <div className="b-m-w-15 f-s-20 f-w-b l-h-16 c-333 w-b-a deepnews-cover-content-noimage">{detail.title}</div> : ''
          } */}
          {
            detail.title ? <div className="b-m-w-15 f-s-20 f-w-b l-h-16 c-1e252b w-b-a o-h t-o-e l-c-3 deepnews-cover-content-noimage">{detail.title}</div> : ''
          }

          <div className={`b-m-w-15 ${!!detail.cover_img ? 'm-t-20' : 'm-t-10'}`}>
            {
              detail.type === 20 ? (!!detail.author && !!detail.author.id ?
                <div className="d-f f-s-c">
                  <div className="f-s-0 o-h deepnews-head-image-box-lj">
                    <img alt="" className="w-100p h-100p o-f-c" src={!!detail.author && detail.author.head_img ? detail.author.head_img : 'https://file.jingpt.com/wap/images/default_avatar-830.png'} />
                  </div>
                  <div className="f-g-1 o-h">
                    {
                      (!!detail.author && detail.author.user_name) &&
                      <div className="w-s-n o-h t-o-el f-s-14 l-h-114286 c-414141 deepnews-cover-content-source-author">
                        {detail.author.user_name}
                      </div>
                    }
                    <div className="f-s-12 l-h-114286 c-b0b6c0">
                      {!!detail.ctime && dayjs(detail.ctime * 1000).format('YYYY-MM-DD HH:mm')}
                      {!!detail.view_num && <><span className="deepnews-cover-content-source-icon">·</span>{`阅${detail.view_num}`}</>}
                    </div>
                  </div>
                </div> :
                <div className="d-f f-s-c f-s-14 l-h-114286 c-b0b6c0">
                  {detail.ctime && dayjs(detail.ctime * 1000).format('YYYY-MM-DD HH:mm')}
                  {!!detail.view_num && <><span className="deepnews-cover-content-source-icon">·</span>{`阅${detail.view_num}`}</>}
                </div>) :
                <div className="f-s-14 l-h-133333 c-b0b6c0">
                  {
                    !!detail.from_source && <>
                      <span className="c-787878 deepnews-cover-content-creator">{detail.from_source}</span>
                      <span className="f-s-0 deepnews-cover-content-source-icon">·</span>
                    </>
                  }
                  {detail.ctime && <span className="f-s-0">{dayjs(detail.ctime * 1000).format('YYYY-MM-DD HH:mm')}</span>}
                  {!!detail.view_num && <><span className="deepnews-cover-content-source-icon">·</span><span className="f-s-0">{`阅${detail.view_num}`}</span></>}
                </div>
            }
          </div>
        </section>
      }

      {
        Array.isArray(detail.labels) && !!detail.labels.length && detail.type !== 20 &&
        <section className="p-r d-f f-b-c bg-c-203051f5f6fa deepnews-head-box">
          <div className="d-f f-s-c f-1">
            <div className="f-s-0 o-h default-deepnews-head-image deepnews-head-image-box">
              {!!detail.labels[0].head_img && <img alt="" className="w-100p h-100p o-f-c" src={detail.labels[0].head_img} />}
            </div>
            <div className="d-f f-c-s f-d-c f-1 m-r-10" onClick={openApp}>
              <div className="f-s-16 f-w-n-b c-ffffff191c27 t-o-e l-c-1 o-h">{detail.labels[0].name}</div>
              <div className="f-s-14 c-565f71 t-o-e l-c-1 o-h">{detail.labels[0].remark}</div>
            </div>
          </div>
          <div className="follow-action" onClick={openApp}>关注</div>
        </section>
      }

      <section className="b-p-w-15 bg-c-171b1fffffff deepnews-content-box">
        <div className="t-a-c deepnews-content-author">
          <div className="d-f f-c-c">
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
          </div>
          <div className="d-f f-c-c">
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
            <div className="bg-c-d13333 deepnews-content-author-animations-item" />
          </div>
        </div>

        {!!detail.abstract_info && <div className="m-b-20 f-s-17 l-h-167406 c-9a9a9a">{detail.abstract_info}</div>}

        <div className="f-s-18 l-h-177778 c-ffffff191c27 deepnews-content-report" dangerouslySetInnerHTML={{ __html: detail.content }} />

        <View item={imageItem} handleOpenImage={handleOpenImage} index={index} />

        {
          Array.isArray(detail.topics) && !!detail.topics.length &&
          <div className="f-s-12 c-9a9a9a deepnews-content-topic-box">
            <span className="m-r-5">#</span>
            {
              detail.topics.map((item, index) => {
                return (<a className="c-9a9a9a" key={item.id} href={setUrl('topic.html', query, item.id)}>{item.name}{index !== detail.topics.length - 1 ? '·' : ''}</a>)
              })
            }
          </div>
        }

        {
          !!detail.creator_detail &&
          <div className="p-r o-h bg-c-7e9acb-13294abfdfff-70a8e5 deepnews-creator-box">
            <div className="deepnews-creator-avatar">
              <Avatar size="small" item={detail.creator_detail || {}} nofollow={true} isArticle={true} />
            </div>
            <div className="o-h f-s-12 l-h-15 c-fff deepnews-creator-description">{detail.creator_detail.title}</div>
            <div className="d-f f-s-c f-w w-100p o-h f-s-12 l-h-166667 c-f4d49c deepnews-creator-tag-box">
              <div className="c-fff">关注领域</div>
              {
                Array.isArray(detail.creator_detail.focus_areas) && !!detail.creator_detail.focus_areas.length &&
                detail.creator_detail.focus_areas.map((item, index) => <div key={`${item.id}-${index}`} className="b-c-f4d49c deepnews-creator-tag">{item.industry}</div>)
              }
            </div>
          </div>
        }

        {
          !!detail.id && detail.type === 20 &&
          <div className="f-s-13 l-h-169231 c-a6a6a6 deepnews-content-declaration">
            声明：本文内容仅代表该投稿文章作者观点，不代表蓝鲸号立场。未经授权不得随意转载，蓝鲸号保留追究相应责任的权利
          </div>
        }

        {
          Array.isArray(detail.tags) && detail.tags.length > 0 &&
          <div className="d-f f-s-c f-w deepnews-relate-tag">
            {
              detail.tags.map(tag => (
                <a className="f-s-12 l-h-141667 c-1a8cff deepnews-relate-tag-item" href={window.globalInformation.ljhost ? `${window.globalInformation.ljhost}/tag/${tag.id}` : setUrl('tag.html', query, tag.id)} key={tag.id}>#{tag.name}</a>
              ))
            }
          </div>
        }
      </section>

      {
        Array.isArray(detail.expert_list) && !!detail.expert_list.length &&
        <section className="bg-c-171b1fffffff b-c-0f1214f8fafd deepnews-relate-profess">
          <div className="f-s-18 c-ffffff0a0b10 f-w-b deepnews-relate-profess-title">相关专家</div>
          {
            detail.expert_list.map(item => {
              return (
                <div key={item.user_name} className="d-f f-b-c deepnews-relate-profess-item">
                  <div className="f-1">
                    <div className="l-c-1 o-h t-o-e f-s-16 f-w-n-b c-ffffff191c27">{item.user_name}</div>
                    <div className="l-c-1 o-h t-o-e f-s-14 c-c8c9da565f71 deepnews-relate-profess-item-company">{`${item.company_name} ${item.profession}`}</div>
                    <div className="l-c-1 o-h t-o-e f-s-13 c-6465669ea5bb deepnews-relate-profess-item-field">{`关注领域：${item.focus_on}`}</div>
                  </div>
                  <div className="f-s-14 c-fff bg-c-d95757339aff-84bfff t-a-c deepnews-relate-profess-item-action" onClick={openApp}>请求采访</div>
                </div>
              )
            })
          }
        </section>
      }

      {
        Array.isArray(detail.news_list) && !!detail.news_list.length &&
        <section className="b-p-w-15 bg-c-171b1fffffff b-c-0f1214f8fafd deepnews-relate-article">
          <div className="f-s-18 c-ffffff0a0b10 f-w-b deepnews-relate-article-title">相关文章</div>
          {
            detail.news_list.map(item => {
              return (
                <a key={item.tid} className="d-f deepnews-relate-article-item" href={setUrl(location.pathname, query, item.tid)}>
                  {item.cover_img && <img alt="" src={item.cover_img} className="f-s-0 deepnews-relate-article-item-img" />}
                  <div className={`d-f f-d-c f-b-s f-1 ${item.cover_img ? 'deepnews-relate-article-item-box' : ''}`}>
                    <div className="w-100p f-s-16 f-w-n-b c-ffffff191c27 l-h-1375 t-o-e l-c-2 o-h deepnews-relate-article-item-title">{item.title}</div>
                    <div className={`w-100p d-f f-b-c f-s-13 c-6465669ea5bb o-h ${item.cover_img ? 'deepnews-relate-article-item-actions' : ''}`}>
                      <div className="t-a-l l-c-1 o-h t-o-e deepnews-relate-article-time">{`${item.creator} ${dateDiff(!!item.r_time ? item.r_time * 1000 : dayjs().valueOf())}`}</div>
                      <div className="t-a-r l-c-1 o-h t-o-e deepnews-relate-article-num">{item.view_num || 0} 阅读</div>
                    </div>
                  </div>
                </a>
              )
            })
          }
        </section>
      }
    </>
  );
};

export default Deepnews;