import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import xss from 'xss';
import { getUseParams } from '../utils/baseUtil';
import { setWeixinData } from '../utils/wxShareUtil';
import OpenApp from '../components/OpenApp';
import AudioPlayer from '../components/AudioPlayer';
import MutiClampText from '../components/MutiClampText';
import { openApp } from '../utils/baseUtil';
import { dateDiff } from '../utils/dateUtil';

const Explain = () => {
  const location = useLocation();
  const query = getUseParams(location.search);

  const [detail, setDetail] = useState({});
  const [expertList, setExpertList] = useState([]);
  const audio = useRef({});
  const [currentAudioId, setCurrentAudioId] = useState(0);
  const audioProgress = useRef({});

  const audioSetExpertList = (newList) => {
    setExpertList(newList);
  };

  const getDetail = () => {
    axios.get(`/api2/proficient/qa/explan/detail`, {
      timeout: 5000,
      params: {
        eid: query.id
      }
    }).then((response) => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0 && response.data.data) {
          const data = response.data.data;
          setDetail(data);
          if (typeof setWeixinData !== 'undefined') {
            setWeixinData(data.name, data.remark, data.share_logo);
          }
          Array.isArray(data.list) && setExpertList(data.list);
        }
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const toDetail = (id) => {
    if(id) {
      window.location.href = `qa_viewpoint.html?id=${id}&os=${query.os || 'web'}&sv=${query.sv || ''}`;
    }
  }

  const open = (e) => {
    e.stopPropagation()
    openApp()
  }

  useEffect(() => {
    query.id && getDetail();
  }, [query.id]);

  return (
    <>
      <Helmet>
        <body className="bg-c-f8fafd s-body explain" />
        <title>{detail.name || ''}</title>
      </Helmet>
      <OpenApp />
      <div className="explain-box">
        <div className="p-r explain-top">
          <img className="p-a explain-top-bg" src={"https://file.jingpt.com/wap/images/explain-top-bg.png"} alt=""/>
          <img className="p-a explain-top-bt" src={"https://file.jingpt.com/wap/images/explain-top-bt.png"} alt=""/>
          <div className="d-f p-a explain-top-title">
            <div className="explain-top-label">
              <div className="p1">{detail.proficients}位</div><div className="p2">专家联合解读</div>
            </div>
            <img className="explain-top-icon" src={"https://file.jingpt.com/wap/images/explain-top-icon.png"} alt=""/>
          </div>
        </div>
        <div className="bg-c-f8fafd explain-intro">
          <div className="d-f f-s-c m-b-15">
            <div className="o-h default-head-icon explain-intro-icon">
              {detail.head_img && <img className="w-100p h-100p o-f-c" src={detail.head_img} alt=""/>}
            </div>
            <div className="t-o-e l-c-2 explain-intro-title">{detail.name}</div>
          </div>
          <div className="d-f">
            <MutiClampText
              className="f-s-14 l-h-142858 c-565f71"
              text={detail.remark}
              line={3}
              openIcon={<span className="d-f f-c-c clamp-icon clamp-open-icon">展开</span>}
              closeIcon={<span className="d-f f-c-c clamp-icon clamp-close-icon">收起</span>}
            />
          </div>
        </div>
        <div className="explain-detail">
          <div className="f-s-20 d-f f-s-c explain-detail-title">
            <img src={"https://file.jingpt.com/wap/images/explain-info.png"} alt=""/><span>专家解读</span>
          </div>
          {
            Array.isArray(expertList) && expertList.map(item => {
              let { id, creator, create_time, title, content_type, content, zan_count, comment_count } = item
              if (Object.prototype.toString.call(creator) !== '[object Object]') creator = {}
              return (<div key={id} className="bg-c-fff explain-item" onClick={() => toDetail(id)}>
                <div className="d-f f-b-c explain-avatar">
                  <div className="d-f f-s-c explain-avatar-info">
                    <div className="o-h default-avatar person-top-avatar-box explain-avatar-img">
                      {!!creator.head_img && <img alt="" className="w-100p h-100p o-f-c" src={creator.head_img} />}
                    </div>
                    <div className="f-s-12 c-9ea5bb explain-avatar-title">
                      <div className="explain-avatar-desc"><span className="f-s-14 c-191c27 explain-avatar-name">{creator.real_name || creator.user_name || ''}</span>{creator.company_name || ''} {creator.title || creator.position || ''}</div>
                      <span>{dateDiff(create_time * 1000)}</span>
                    </div>
                  </div>
                  <div className="explain-avatar-opt">
                    <div className="follow-action" onClick={open}>关注</div>
                  </div>
                </div>
                <div className="f-s-18 c-191c27 t-o-e l-c-2 o-h explain-item-title">{title}</div>
                <div className="explain-item-content">
                  {/* 0 文本 */}
                  {content_type === 0 &&  <div className="t-o-e l-c-4 o-h f-s-15 c-565f71 editor-text" dangerouslySetInnerHTML={{__html: typeof content === 'string' ? xss(content) : null}}/>}
                  {/* 1 录音 */}
                  {content_type === 1 && <AudioPlayer
                    item={item}
                    expertList={expertList}
                    setExpertList={audioSetExpertList}
                    currentAudioId={currentAudioId}
                    setCurrentAudioId={setCurrentAudioId}
                    audio={audio}
                    audioProgress={audioProgress}
                  />
                  }
                </div>
                <div className="d-f f-b-c c-9ea5bb explain-item-count">
                  <div className="d-f f-c-c"><img className="c-p" src={"https://file.jingpt.com/wap/images/explain-like.png"} onClick={open} alt=""/>{zan_count}</div>
                  <div className="d-f f-c-c"><img className="c-p" src={"https://file.jingpt.com/wap/images/explain-comment.png"} onClick={open} alt=""/>{comment_count}</div>
                </div>
              </div>)
            })
          }
        </div>
      </div>
    </>
  );
};

export default Explain;