import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import xss from 'xss';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import OpenApp from '../components/OpenApp';
import Avatar from '../components/qa/Avatar';
import Comment from '../components/qa/Comment';
import AudioPlayer from '../components/AudioPlayer';
import {getUseParams, openApp} from '../utils/baseUtil';
import {setWeixinData} from '../utils/wxShareUtil';

const ViewPoint = () => {
  const location = useLocation();
  const query = getUseParams(location.search);

  const [data, setData] = useState({});
  const [dataError, setDataError] = useState('');

  const audio = useRef({});
  const [currentAudioId, setCurrentAudioId] = useState(0);
  const audioProgress = useRef({});

  useEffect(() => {
    if (!!query.id) {
      axios.get(`/api2/proficient/qa/${query.id}`, {
        timeout: 5000,
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && response.data.data && !!response.data.data.id) {
            const data = response.data.data;
            if (typeof setWeixinData !== 'undefined') {
              const newContent = typeof data.content === 'string' ? data.content.replace(/<[^>]+>/gi, '') : data.content
              setWeixinData(data.title, newContent || '打开蓝鲸财经客户端，收听权威专家解读', data.share_logo);
            }
            setData(data);
          } else {
            setDataError('内容已下架/删除');
          }
        }
      }).catch((error) => {
      });
    }
  }, [query.id]);

  const audioSetExpertList = (newList) => {
    if (Array.isArray(newList) && !!newList.length) {
      const firstData = Object.assign({}, newList[0] || {});
      setData(firstData);
    }
  };

  return (
    <>
    <Helmet>
      <body className="bg-c-171b1fffffff s-body" />
      <title>{data.title || ''}</title>
    </Helmet>
    <OpenApp />
    {
      !!dataError ? <div className="f-s-14 t-a-c c-6465669ea5bb page-404">{dataError}</div> :
        <>
        <section className="b-m-w-16 viewpoint-top-box" onClick={openApp}>
          <Avatar type="proficient" size="small" item={data.creator || {}} />
        </section>
        <section className="b-m-w-16 w-b-a">
          <div className="f-s-18 f-w-b l-h-144444 c-ffffff0a0b10 viewpoint-title">{data.title}</div>
          <div className="f-s-16 l-h-15 c-afb0b3191c27 t-a-j viewpoint-content">
            {
              data.content_type === 1 ?
                <AudioPlayer
                  item={data}
                  expertList={[data]}
                  setExpertList={audioSetExpertList}
                  currentAudioId={currentAudioId}
                  setCurrentAudioId={setCurrentAudioId}
                  audio={audio}
                  audioProgress={audioProgress}
                /> :
                <div className="editor-text qa-viewpoint-content" dangerouslySetInnerHTML={{__html: typeof data.content === 'string' ? xss(data.content) : null}} />
            }
          </div>
          <div className="d-f f-b-c f-s-13 c-565f719ea5bb viewpoint-tip">
            <div className="t-o-l">{data.create_time && `编辑于${dayjs(data.create_time * 1000).format('YYYY-MM-DD HH:mm')} · `}著作权归作者所有</div>
            {typeof data.view_num === 'number' && data.view_num > 0 && <div className="t-o-r">{data.view_num}人查看</div>}
          </div>
        </section>
        {Array.isArray(data.comment_list) && <Comment item={data.comment_list || []} />}
        </>
      }
    </>
  );
};

export default ViewPoint;