import React, {useEffect} from 'react';
import store from '../store';
import {observer} from 'mobx-react';
import Cookies from 'js-cookie';
import axios from 'axios';
import OpenAppChannel from './OpenAppChannel';

const OpenApp = (props) => {
  const cookieToken = Cookies.get('_LJWAP_TOKEN');
  const cookieUser = Cookies.get('_LJWAP_USER');

  useEffect(() => {
    if (!!cookieToken && !!cookieUser) {
      store.setToken(cookieToken);
      store.setUser(cookieUser);
    }
  }, [cookieToken, cookieUser]);

  const token = store.token || '';
  useEffect(() => {
    if (!!token) {
      axios.get('/api2/user/center', {
        headers: {
          os: 'web',
          token
        },
        timeout: 5000,
      }).then((response) => {
        if (!!response && response.status === 200 && response.data) {
          store.setUserInformation(response.data);
        }
      }).catch((error) => {

      });
    }
  }, [token]);

  const logout = () => {
    axios({
      method: 'post',
      url: '/api/v1/log_out',
      data: {
        'token': cookieToken
      },
      timeout: 5000,
    }).then((response) => {
      if (!!response && response.status === 200) {
        if (response.data.code === 200) {
          store.setToken('');
          store.setUser('');
          store.setUserInformation({});
          Cookies.remove('_LJWAP_TOKEN', { path: '' });
          Cookies.remove('_LJWAP_USER', { path: '' });
        }
      }
    }).catch((error) => {

    });
  };

  return (
    <div className="p-r d-f f-b-c m-w-480 bg-c-171b1fa8e3ff-ffffff share-openapp-wrap">
      <div className="d-f f-s-c">
        <div className="f-s-0 w-100p h-100p o-f-c share-openapp-logo" />
      </div>
      <div className="d-f f-s-c f-s-14 l-h-142858 c-e1e2e6191c27">
        <OpenAppChannel openUrl={props.openUrl} handleOpenApp={props.handleOpenApp}>
          打开APP
        </OpenAppChannel>
        {
          !!store && !!store.token && !!store.user &&
          <div className="b-c-aebfc8 share-openapp-logout" onClick={() => logout()}>
            登出
          </div>
        }
      </div>
    </div>
  )
};

export default observer(OpenApp);