import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import store from '../store';
import { observer } from 'mobx-react';
import axios from 'axios';
import OpenApp from '../components/OpenApp';
import { lockFixed } from '../utils/baseUtil';
import LArea from '../utils/LArea';
import { allProvince, allCity, allArea } from '../utils/city'
import { setWeixinHideMenuData } from '../utils/wxShareUtil'

let errorTimer = null;

const Authentication = (props) => {
  const location = useLocation();
  let history = useHistory();

  const applyType = props.type || '';
  const phone = props.phone || '';

  const token = store.token || '';
  const userInformation = store.userInformation || {};

  const [isShowTip, setIsShowTip] = useState(true);
  const [avatar, setAvatar] = useState('');
  const [avatarError, setAvatarError] = useState('');
  const [certificate, setCertificate] = useState('');
  const [certificateError, setCertificateError] = useState('');

  const [resourceIndustry, setResourceIndustry] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  const [searchIndustryList, setSearchIndustryList] = useState([]);

  const [selectIndustryInput, setSelectIndustryInput] = useState({ list: [], value: '' });
  const [searchInput, setSearchInput] = useState('');

  const [formData, setFormData] = useState({
    phone,
    applyType: applyType || 1
  });
  const [errorText, setErrorText] = useState('');

  const authType = { 2: '记者', 3: '专家', 5: '蓝鲸' }
  const authTitle = { 2: '如主编（必填）', 3: '如首席专家（必填）', 5: '' }
  const authBrief = { 2: '丰富的简介将有助于邀约专家更好的了解你，提升专家接受邀约几率', 3: '丰富的简介将有助于提升被记者邀约的几率', 5: '' }

  useEffect(() => {
    return () => {
      clearTimeout(errorTimer);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      history.goBack();
    }
  }, [token, history]);

  useEffect(() => {
    setWeixinHideMenuData(`${authType[applyType] || ''}认证`, '', 'https://file.jingpt.com/wap/images/88.png')
    if (document.body) {
      document.body.scrollTop = 0
    }
    if (document.documentElement) {
      document.documentElement.scrollTop = 0
    }
  }, [applyType]);

  useEffect(() => {
    if (!!userInformation && userInformation.code === 0 && !!userInformation.data && !!userInformation.data.uid) {
      const data = userInformation.data || {};
      setFormData({
        ...formData,
        real_name: data.real_name || '',
        title: data.title || '',
        city: data.city || '',
        career_duration: data.career_duration || 0,
        self_description: data.desc || '',
        organization_name: data.auth_org_name || '',
        position: data.auth_position || ''
      });
      setAvatar(data.head_img || '');
      setCertificate(data.auth_certificate || '');
      setSelectIndustryInput({
        list: Array.isArray(data.focus_areas) ? data.focus_areas : [],
        value: Array.isArray(data.focus_areas) ? data.focus_areas.map(item => item.industry).join('、') : ''
      });
    }
  }, [userInformation]);

  useEffect(
    () => {
      let newAllCity = {}
      Object.keys(allCity).forEach(key => {
        if (Array.isArray(allCity[key])) {
          if (allCity[key].length > 1) {
            newAllCity[key] = allCity[key]
          } else {
            newAllCity[key] = []
            if (allCity[key][0].id && allArea[allCity[key][0].id]) {
              allArea[allCity[key][0].id].forEach(item => {
                newAllCity[key].push({
                  province: allCity[key][0].province,
                  name: item.name,
                  id: item.id
                })
              })
            }
          }
        }
      })
      var area = new LArea();
      area.init({
        'trigger': '#cityInput',//触发选择控件的文本框，同时选择完毕后name属性输出到该位置
        // 'valueTo':'#value1',//选择完毕后id属性输出到该位置
        'keys': { id: 'id', name: 'name' },//绑定数据源相关字段 id对应valueTo的value属性输出 name对应trigger的value属性输出
        'type': 2,//数据源类型
        'data': [allProvince, newAllCity]//数据源
      });
    },
    []
  );

  const upImage = (event, type) => {
    let imagefile = event.currentTarget.files[0];
    if (imagefile.size > 3072000) {
      if (type === 'avatar') {
        setAvatarError('头像大于了3MB，请重新上传');
      } else {
        setCertificateError('职业证明大于了3MB，请重新上传');
      }
    } else {
      let form = new FormData();
      form.append('file', imagefile);
      form.append('group', 'img');
      axios({
        method: 'post',
        url: '/api2/file/upload',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form
      }).then(response => {
        if (!!response && response.status === 200 && response.data) {
          if (response.data.code === 0 && !!response.data.data && response.data.data.url) {
            if (!!type) {
              if (type === 'avatar') {
                setAvatar(response.data.data.url);
                setAvatarError('')
              } else {
                setCertificate(response.data.data.url);
                setCertificateError('');
              }
            }
          }
        }
      }).catch((error) => {
        if (type === 'avatar') {
          setAvatar('');
          setAvatarError('头像上传失败，请重试')
        } else {
          setCertificate('');
          setCertificateError('职业证明上传失败，请重试');
        }
      });
    }
  };

  const openSelectIndustry = () => {
    if (!!selectIndustryInput && Array.isArray(selectIndustryInput.list)) {
      setSelectIndustry(selectIndustryInput.list)
    }
    const id = Array.isArray(selectIndustryInput.list) ? selectIndustryInput.list.map(item => item.id) : [];
    axios.get('/api2/proficient/info/industry').then(response => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0 && Array.isArray(response.data.data)) {
          lockFixed.lock('.main-box');
          const list = response.data.data;
          setResourceIndustry(list);
          setIndustry(
            list.map((item) => {
              let hasOpen = false;
              Array.isArray(item.sub) && item.sub.forEach(subItem => {
                if (id.includes(subItem.id)) {
                  hasOpen = true;
                }
              });
              return Object.assign(item, { open: hasOpen })
            })
          )
        }
      }
    }).catch((error) => {
    });
  };

  const closeSelectIndustry = (type) => {
    lockFixed.unlock('.main-box');
    setResourceIndustry([]);
    setIndustry([]);
    setSelectIndustry([]);
    setSearchIndustryList([]);
    setSearchInput('');
    if (type === 'ok') {
      setSelectIndustryInput({
        list: selectIndustry,
        value: selectIndustry.map(item => item.industry).join('、')
      });
    }
  };

  const openIndustryItem = (index) => {
    setIndustry(
      industry.map((item, itemIndex) => {
        return Object.assign(item, { open: index === itemIndex ? !item.open : item.open })
      })
    );
  };

  const selectIndustryItem = (item) => {
    const id = selectIndustry.map(item => item.id);
    const index = id.findIndex(idItem => idItem === item.id);
    const list = [].concat(selectIndustry);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(item);
    }
    setSelectIndustry(list);
  };

  const searchIndustry = (event) => {
    setSearchInput(event.target.value || '');
    if (!!event.target.value) {
      const value = event.target.value;
      const searchList = [];
      resourceIndustry.forEach(item => {
        Array.isArray(item.sub) && item.sub.forEach(subItem => {
          if (subItem.industry.includes(value)) {
            searchList.push(subItem);
          }
        });
      });
      setSearchIndustryList(searchList);
    } else {
      setSearchIndustryList([]);
      const id = selectIndustry.map(item => item.id);
      setIndustry(
        industry.map((item) => {
          let hasOpen = false;
          Array.isArray(item.sub) && item.sub.forEach(subItem => {
            if (id.includes(subItem.id)) {
              hasOpen = true;
            }
          });
          return Object.assign(item, { open: hasOpen || item.open })
        })
      );
    }
  };

  const changeInput = (event, attribute) => {
    const newObject = Object.assign({}, formData);
    newObject[attribute] = event.target.value;
    setFormData(newObject);
  };

  const handleSubmit = () => {
    const data = {
      avatar,
      career_duration: isNaN(formData.career_duration) ? 0 : Number(formData.career_duration),
      certificate,
      city: formData.city,
      organization_name: formData.organization_name,
      phone,
      position: formData.position,
      proficient_industry: Array.isArray(selectIndustryInput.list) ? selectIndustryInput.list.map(item => ({ code: item.code, industry_id: item.id })) : [],
      real_name: formData.real_name,
      self_description: formData.self_description,
      title: formData.title,
      type: applyType
    };

    axios({
      method: 'post',
      url: '/api2/authentication/info',
      headers: {
        token
      },
      params: {
        os: 'web'
      },
      data
    }).then(response => {
      if (!!response && response.status === 200 && response.data) {
        if (response.data.code === 0) {
          history.push({ pathname: 'qa_result.html', search: location.search });
        } else {
          if (response.data.msg) {
            drawErrorModal(response.data.msg)
          }
        }
      } else {
        drawErrorModal('连接出错，请稍后再试')
      }
    }).catch((error) => {

    });
  };

  const drawErrorModal = (msg) => {
    if (msg) {
      setErrorText(msg);
      clearTimeout(errorTimer);
      errorTimer = setTimeout(() => {
        setErrorText('');
      }, 3500)
    }
  };

  const canSubmit = !!avatar && (applyType === 5 ? true : (!!formData.career_duration)) && !!certificate && !!formData.city && !!formData.organization_name && !!phone &&
    !!formData.position && (!!selectIndustryInput && !!selectIndustryInput.value) && !!formData.real_name && !!formData.self_description && !!formData.title && !!authType[applyType];

  return (
    <>
      <Helmet>
        <body className="bg-c-171b1ff8fafd s-body" />
        <title>{`${authType[applyType] || ''}认证`}</title>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/LArea.css`} />
      </Helmet>
      <div className="main-box">
        <OpenApp />
        {
          isShowTip &&
          <section className="d-f b-p-w-16 f-b-c f-s-12 l-h-166667 c-afb0b39ea5bb bg-c-1e252bffffff authentication-tip-box">
            <div className="f-g-1">您填写的内容将用于个人页展示以及内容推荐，请认真填写</div>
            <div className="d-f f-c-c f-s-0 c-171b1fffffff bg-c-2c38409ea5bb-20 authentication-tip-close" onClick={() => setIsShowTip(false)}>✕</div>
          </section>
        }
        <section className="d-f f-s-c b-p-w-16 p-r bg-c-tffffff authentication-avatar-upload-image-box">
          <div className="p-r o-h authentication-avatar-upload-image">
            <img alt="" className="w-100p h-100p o-f-c" src={avatar || "https://file.jingpt.com/wap/images/default_avatar-830.png"} />
            <svg className="authentication-avatar-upload-icon" width="23" height="21" viewBox="0 0 23 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path className="f-f0f0f5" d="M4.81818182,4.38636364 L4.81818182,3.25 C4.81818182,1.99480739 5.83571648,0.977272727 7.09090909,0.977272727 L16.1818182,0.977272727 C17.4370108,0.977272727 18.4545455,1.99480739 18.4545455,3.25 L18.4545455,4.38636364 L20.7272727,4.38636364 C21.9824653,4.38636364 23,5.4038983 23,6.65909091 L23,18.0227273 C23,19.2779199 21.9824653,20.2954545 20.7272727,20.2954545 L2.54545455,20.2954545 C1.29026193,20.2954545 0.272727273,19.2779199 0.272727273,18.0227273 L0.272727273,6.65909091 C0.272727273,5.4038983 1.29026193,4.38636364 2.54545455,4.38636364 L4.81818182,4.38636364 Z M11.6363636,18.0227273 C14.7743452,18.0227273 17.3181818,15.4788906 17.3181818,12.3409091 C17.3181818,9.20292756 14.7743452,6.65909091 11.6363636,6.65909091 C8.4983821,6.65909091 5.95454545,9.20292756 5.95454545,12.3409091 C5.95454545,15.4788906 8.4983821,18.0227273 11.6363636,18.0227273 Z M11.6363636,16.3181818 C9.43977656,16.3181818 7.65909091,14.5374962 7.65909091,12.3409091 C7.65909091,10.144322 9.43977656,8.36363636 11.6363636,8.36363636 C13.8329507,8.36363636 15.6136364,10.144322 15.6136364,12.3409091 C15.6136364,14.5374962 13.8329507,16.3181818 11.6363636,16.3181818 Z" />
            </svg>
          </div>
          {!avatar && <div className="f-s-14 l-h-142858 c-6465669ea5bb">{avatarError || '推荐使用个人形象照'}</div>}
          <input type="file" accept="image/*" className="w-100p h-100p authentication-avatar-upload" onChange={(event) => upImage(event, 'avatar')} />
        </section>
        <section className="f-s-14 l-h-142858 c-afb0b39ea5bb b-c-0f1214f1f1fb authentication-basic-box">
          <div className="b-m-w-16 d-f f-b-c authentication-title">
            <div className="f-s-18 f-w-b l-h-144444 c-ffffff191c27">基本资料</div>
            <div className="f-s-12 l-h-166667 c-6465669ea5bb">为必填信息</div>
          </div>
          <div className="b-p-w-16">
            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">姓名</div>
              <input type="text" value={formData.real_name || ''} placeholder="请填写真实姓名（必填）" autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'real_name')} />
            </div>

            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">展示头衔</div>
              <input type="text" value={formData.title || ''} placeholder={authTitle[applyType] || ''} autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'title')} />
            </div>

            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">办公城市</div>
              <input id="cityInput" type="text" value={formData.city || ''} readOnly name="input_area" placeholder="选择你所在的城市" autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'city')} onInputCapture={event => changeInput(event, 'city')} />
              <span className="c-c7c8d9 authentication-input-select-icon">﹥</span>
            </div>

            {
              applyType !== 5 && <div className="p-r d-f f-s-c authentication-input-box">
                <div className="f-s-0 authentication-input-text">从业年限</div>
                <input type="number" pattern="\d*" value={formData.career_duration || ''} placeholder="从业年限（年）" autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'career_duration')} />
              </div>
            }

            <div className="p-r d-f f-s-c authentication-input-box" onClick={openSelectIndustry}>
              <div className="f-s-0 authentication-input-text">擅长领域</div>
              <input type="text" readOnly placeholder="填写擅长的领域" autoComplete="off" value={selectIndustryInput.value || ''} className="f-g-1 c-ffffff191c27 authentication-input" />
              <span className="c-c7c8d9 authentication-input-select-icon">﹥</span>
            </div>

            <div className="p-r">
              <div className="f-s-0 authentication-input-text">个人简介</div>
              <textarea rows="3" value={formData.self_description || ''} placeholder={authBrief[applyType] || ''} autoComplete="off" className="w-100p c-ffffff191c27 authentication-textarea" onChange={event => changeInput(event, 'self_description')} />
            </div>
          </div>
        </section>

        <section className="f-s-14 l-h-142858 c-afb0b39ea5bb authentication-information-box">
          <div className="b-m-w-16 d-f f-b-c authentication-title">
            <div className="f-s-18 f-w-b l-h-144444 c-ffffff191c27">认证信息</div>
            <div className="f-s-12 l-h-166667 c-6465669ea5bb">为必填信息</div>
          </div>
          <div className="b-p-w-16">
            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">机构名称</div>
              <input type="text" value={formData.organization_name || ''} placeholder="所在机构名称" autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'organization_name')} />
            </div>

            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">职位</div>
              <input type="text" value={formData.position || ''} placeholder="所在公司职位、荣誉头衔等" autoComplete="off" className="f-g-1 c-ffffff191c27 authentication-input" onChange={event => changeInput(event, 'position')} />
            </div>

            <div className="p-r authentication-input-box">
              <div>上传证明<span className="c-6465669ea5bb">（名片或其他可以证明单位和职位的证件）</span></div>
              <div className="p-r authentication-prove-box">
                <div className="p-r o-h authentication-prove">
                  {!!certificate && <img alt="" src={certificate} className="w-100p h-100p o-f-c" />}
                  <div className="w-100p t-a-c authentication-prove-icon-box">
                    <div className="f-s-0 d-f f-c-c w-100p authentication-prove-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g fill="transparent" className="s-c7c8d9ffffff" strokeWidth="1.33333333">
                          <rect x="0.666666667" y="0.666666667" width="22.6666667" height="22.6666667" rx="2.66666667" />
                          <polyline points="0.666666667 20.6666667 8 12.6666667 12.6666667 17.3333333 23.3333333 6" />
                          <circle cx="6.66666667" cy="6.66666667" r="2" />
                        </g>
                      </svg>
                    </div>
                    <div className="c-646566ffffff">{certificateError || '上传职业证明照片'}</div>
                  </div>
                  <input type="file" accept="image/*" className="w-100p h-100p authentication-prove-input" onChange={(event) => upImage(event, 'certificate')} />
                </div>
              </div>
            </div>

            <div className="p-r d-f f-s-c authentication-input-box">
              <div className="f-s-0 authentication-input-text">+86</div>
              <input type="text" readOnly value={phone} className="f-g-1 c-ffffff191c27 authentication-input" />
            </div>
            {
              canSubmit ? <div className="d-f f-c-c f-s-16 f-w-b c-fff bg-c-d95757339aff-84bfff authentication-button-box" onClick={handleSubmit}>提交</div> :
                <div className="d-f f-c-c f-s-16 f-w-b c-afb0b3ffffff bg-c-1e252b9ea5bb authentication-button-box">有未填内容，无法提交</div>
            }
          </div>
        </section>
      </div>

      {
        Array.isArray(industry) && !!industry.length &&
        <section className="w-100p h-100p m-w-480 bg-c-0f1214f8fafd industry-wrap">
          <div className="d-f f-d-c h-100p bg-c-171b1ff8fafd industry-box">
            <div className="b-p-w-16 d-f f-b-c bg-c-171b1ff8fafd industry-top">
              <div className="f-s-14 l-h-157143 c-6465669ea5bb" onClick={() => closeSelectIndustry('cancel')}>取消</div>
              <div className="f-s-16 l-h-15 c-ffffff191c27">选择擅长领域</div>
              <div className="f-s-14 l-h-157143 c-1a8cff" onClick={() => closeSelectIndustry('ok')}>确定</div>
            </div>
            <div className="b-p-w-16 industry-search">
              <input placeholder="搜索关键词" className="w-100p f-s-14 l-h-142858 c-ffffff191c27 bg-c-0f1214ffffff industry-select-input" onChange={searchIndustry} />
            </div>
            <div className="b-p-w-16 industry-selected">
              <div className={`f-s-12 l-h-15 c-ffffff191c27 ${!!selectIndustry.length ? 'industry-select-number' : 'industry-select-no-number'}`}>擅长领域（{selectIndustry.length}）</div>
              {
                !!selectIndustry.length && <div className="clearfix industry-select-item-box">
                  {
                    selectIndustry.map(item => {
                      return (
                        <div key={item.id} className="f-l d-f f-s-c f-s-12 l-h-1 c-1a8cff bg-c-1a8cff-10 industry-select-item">
                          <div>{item.industry}</div>
                          <div className="industry-select-item-close" onClick={() => selectIndustryItem(item)}>✕</div>
                        </div>
                      )
                    })
                  }
                </div>
              }
            </div>

            <div className="b-p-w-16 f-g-1 industry-list">
              {
                Array.isArray(searchIndustryList) && !!searchInput ?
                  <div className="p-r industry-item-box">
                    <div className="d-f f-b-c f-s-14 l-h-142858 c-ffffff191c27 industry-item-title-box">
                      <div>匹配结果</div>
                    </div>
                    <div className="f-s-14 l-h-142858 clearfix">
                      {
                        !!searchIndustryList.length ?
                          searchIndustryList.map(subItem => {
                            return (
                              <div key={subItem.id} className={`f-l ${selectIndustry.map(selectIndustryItem => selectIndustryItem.id).includes(subItem.id) ? 'c-1a8cff bg-c-1a8cff-10' : 'c-969799878f9d bg-c-1e252bf1f6ff'} industry-item`} onClick={() => selectIndustryItem(subItem)}>
                                {subItem.industry}
                              </div>
                            )
                          }) :
                          <div className="c-969799878f9d">无匹配结果</div>
                      }
                    </div>
                  </div> :
                  industry.map((item, index) => {
                    return (
                      <div key={item.id} className="p-r industry-item-box">
                        <div className="d-f f-b-c f-s-14 l-h-142858 c-ffffff191c27 industry-item-title-box" onClick={() => openIndustryItem(index)}>
                          <div>{item.industry}</div>
                          <div className={`c-c7c8d99ea5bb industry-item-icon ${item.open ? 'industry-item-icon-open' : ''}`}>﹥</div>
                        </div>
                        {
                          Array.isArray(item.sub) && !!item.sub.length && item.open &&
                          <div className="f-s-14 l-h-142858 clearfix">
                            {
                              item.sub.map(subItem => {
                                return (
                                  <div key={subItem.id} className={`f-l ${selectIndustry.map(selectIndustryItem => selectIndustryItem.id).includes(subItem.id) ? 'c-1a8cff bg-c-1a8cff-10' : 'c-969799878f9d bg-c-1e252bf1f6ff'} industry-item`} onClick={() => selectIndustryItem(subItem)}>
                                    {subItem.industry}
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                    )
                  })
              }
            </div>
          </div>
        </section>
      }

      {errorText &&
        <section className="w-100p h-100p global-message-wrap">
          <div className="f-s-16 bg-c-000 c-afb0b3 t-a-c global-message-box">{errorText}</div>
        </section>
      }
    </>
  );
};

export default observer(Authentication);